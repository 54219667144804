import React, { Fragment } from "react";
import { format, formatDistance } from "date-fns";
// import differenceInDays from "date-fns/differenceInDays";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  days: {
    marginBottom: 6
  },
  dates: {
    // marginTop: 6
  },
  root: {
    maxWidth: 80,
    // maxHeight: 100,
    display: "flex",
    flexDirection: "column",
    textAlign: "center"
  },
  dayWk: {
    marginTop: theme.spacing(1)
  },
  dayMo: {
    marginBottom: theme.spacing(0.5),
    fontSize: 24,
    fontWeight: "bold"
    // padding: theme.spacing(1),
    // fontSize: 14,
  },
  month: { marginBottom: theme.spacing(1) },
  headings: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.dark
  }
}));

const ListingDates = ({ startDate, endDate }) => {
  const classes = useStyles();

  const formatDays = (startDate, endDate) => {
    const days = differenceInCalendarDays(endDate, startDate);

    if (days >= 1) return `${days + 1} Days`;

    return "1 Day";
  };

  const formatDate = (startDate, endDate) => {
    const days = differenceInCalendarDays(endDate, startDate);

    if (days > 0) {
      return (
        <Fragment>
          {format(startDate, "eee MMM do, y")} to
          <br />
          {format(endDate, "eee MMM do, y")}
        </Fragment>
      );
    } else {
      return <Fragment>{format(startDate, "eee MMM do, y")}</Fragment>;
    }
  };

  return startDate && endDate ? (
    <Fragment>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
      >
        <Grid item xs={4}>
          <Typography className={classes.headings}>Availability</Typography>
          <Typography
            className={classes.days}
            color="textSecondary"
            variant="h5"
            component="h3"
          >
            {formatDays(startDate, endDate)}
          </Typography>
        </Grid>

        {startDate && (
          <Grid item xs={3} className={classes.root}>
            <Typography color="textSecondary" className={classes.headings}>
              Checkin
            </Typography>
            <Card>
              <CardMedia>
                <Typography className={classes.dayWk}>
                  {format(startDate, "eee")}
                </Typography>
                <Typography color="primary" className={classes.dayMo}>
                  {format(startDate, "dd")}
                </Typography>
                <Typography className={classes.month}>
                  {format(startDate, "MMM").toUpperCase()}
                </Typography>
              </CardMedia>
            </Card>
          </Grid>
        )}

        {endDate && (
          <Grid item xs={3} className={classes.root}>
            <Typography color="textSecondary" className={classes.headings}>
              Checkout
            </Typography>
            <Card>
              <CardMedia>
                <Typography className={classes.dayWk}>
                  {format(endDate, "eee")}
                </Typography>
                <Typography color="primary" className={classes.dayMo}>
                  {format(endDate, "dd")}
                </Typography>
                <Typography className={classes.month}>
                  {format(endDate, "MMM").toUpperCase()}
                </Typography>
              </CardMedia>
            </Card>
          </Grid>
        )}
      </Grid>
    </Fragment>
  ) : null;
  // <Typography
  //   className={classes.days}
  //   color="textSecondary"
  //   variant="h5"
  //   component="h3"
  // >
  //   No dates specified
  // </Typography>
};

export default ListingDates;
