// Session reducer types
export const AUTH_USER_SET = "AUTH_USER_SET";
export const AUTH_USER_LOADING = "AUTH_USER_LOADING";
export const STOP_AUTH_USER_LOADING = "STOP_AUTH_USER_LOADING";

// User reducer types
// export const USERS_SET = "USERS_SET";
export const USER_SET = "USER_SET";
export const USER_ADD_FAVORITE = "USER_ADD_FAVORITE";
export const USER_REMOVE_FAVORITE = "USER_REMOVE_FAVORITE";

// Message reducer types
export const CHATS_SET = "CHATS_SET";
export const CHATS_CLEAR = "CHATS_CLEAR";
export const CHATS_LOADING = "CHATS_LOADING";
export const CHAT_SET = "CHAT_SET";
export const CHAT_CLEAR = "CHAT_CLEAR";
export const CHAT_MESSAGES_SET = "CHAT_MESSAGES_SET";
export const CHAT_LOADING = "CHAT_LOADING";
// DEPRECATED:
export const MESSAGES_SET = "MESSAGES_SET";
export const MESSAGES_LIMIT_SET = "MESSAGES_LIMIT_SET";

// Listing reducer types
export const LISTINGS_SET = "LISTINGS_SET";
export const LISTINGS_NEXT_SET = "LISTINGS_NEXT_SET";
export const LISTINGS_LIMIT_SET = "LISTINGS_LIMIT_SET";

// New listing reducer types
export const LISTING_FILE_SET = "LISTING_FILE_SET";
export const LISTING_URL_SET = "LISTING_URL_SET";
export const LISTING_IMAGES_RESET = "LISTING_IMAGES_RESET";
export const LISTING_START_DATE_SET = "LISTING_START_DATE_SET";
export const LISTING_END_DATE_SET = "LISTING_END_DATE_SET";
export const LISTING_DATES_VALID_SET = "LISTING_DATES_VALID_SET";
export const LISTING_TITLE_SET = "LISTING_TITLE_SET";
export const LISTING_TYPE_SET = "LISTING_TYPE_SET";
export const LISTING_LOCATION_SET = "LISTING_LOCATION_SET";
export const LISTING_PLACE_SET = "LISTING_PLACE_SET";
export const LISTING_DESCRIPTION_SET = "LISTING_DESCRIPTION_SET";
export const LISTING_PRICE_SET = "LISTING_PRICE_SET";
export const LISTING_SET = "LISTING_SET";
export const LISTING_RESET = "LISTING_RESET";
export const LISTING_CLOSE_SET = "LISTING_CLOSE_SET";
export const LISTING_OPEN_SET = "LISTING_OPEN_SET";
