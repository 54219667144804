import React, { Component } from "react";
import { compose } from "recompose";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton
} from "react-share";
import {
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon
} from "react-share";

// Material UI
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  paper: {
    // margin: 0,
    // top: "auto",
    // left: "auto",
    left: theme.spacing(1),
    right: theme.spacing(1),
    bottom: theme.spacing(2),
    position: "fixed",
    zIndex: 1000
    // margin: theme.spacing(2)
    // "max-width": 300
  },
  container: {
    margin: theme.spacing(2)
  },
  button: {
    // width: 42,
    cursor: "pointer"
  }
});

class Share extends Component {
  render() {
    const shareUrl = this.props.shareUrl
      ? this.props.shareUrl
      : "https://www.lettuscamp.com";
    const title = this.props.title ? this.props.title : "Come join Lettus!";

    const { classes } = this.props;

    return (
      <Paper className={classes.paper}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.container}
        >
          <Grid item xs={2}>
            <FacebookShareButton
              url={shareUrl}
              quote={title}
              className={classes.button}
            >
              <FacebookIcon size={42} round />
            </FacebookShareButton>
          </Grid>
          <Grid item xs={2}>
            <TwitterShareButton
              url={shareUrl}
              title={title}
              className={classes.button}
            >
              <TwitterIcon size={42} round />
            </TwitterShareButton>
          </Grid>
          <Grid item xs={2}>
            <TelegramShareButton
              url={shareUrl}
              title={title}
              className={classes.button}
            >
              <TelegramIcon size={42} round />
            </TelegramShareButton>
          </Grid>
          <Grid item xs={2}>
            <WhatsappShareButton
              url={shareUrl}
              title={title}
              separator=":: "
              className={classes.button}
            >
              <WhatsappIcon size={42} round />
            </WhatsappShareButton>
          </Grid>
          <Grid item xs={2}>
            <LinkedinShareButton
              url={shareUrl}
              windowWidth={750}
              windowHeight={600}
              className={classes.button}
            >
              <LinkedinIcon size={42} round />
            </LinkedinShareButton>
          </Grid>
          <Grid item xs={2}>
            <EmailShareButton
              url={shareUrl}
              subject={title}
              body="body"
              className={classes.button}
            >
              <EmailIcon size={42} round />
            </EmailShareButton>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default compose(withStyles(styles))(Share);
