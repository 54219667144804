import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import { withFirebase } from "../components/Firebase";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import styles from "../util/styles";

const INITIAL_STATE = {
  email: "",
  error: null
};

class ForgotPasswordFormBase extends Component {
  state = { ...INITIAL_STATE };

  onSubmit = event => {
    event.preventDefault();

    const { email } = this.state;

    this.props.firebase
      .doSendPasswordResetEmail(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;
    const { classes } = this.props;

    const isInvalid = email === "";

    return (
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Typography component="h2" variant="h5">
            Reset Password
          </Typography>
          <Typography component="h2" variant="h5">
            {error && <p>{error.message}</p>}
          </Typography>

          <form className={classes.form} onSubmit={this.onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="email"
                  name="email"
                  value={this.state.email}
                  variant="outlined"
                  autoFocus
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  onChange={this.onChange}
                />
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Grid item>
                <Button
                  disabled={isInvalid}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Send Reset Password Email
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    );
  }
}

const ForgotPasswordLink = () => (
  <p>
    <Link to={"/forgotpassword"}>Forgot Password?</Link>
  </p>
);

const ForgotPasswordForm = withStyles(styles)(
  withFirebase(ForgotPasswordFormBase)
);

export default ForgotPasswordForm;

export { ForgotPasswordForm, ForgotPasswordLink };
