import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import formatDistanceToNow from "date-fns/formatDistanceToNow";
import fmtName from "../../util/fmtName";

// Redux
import { connect } from "react-redux";
import { CHATS_SET, CHATS_CLEAR, CHATS_LOADING } from "../../redux/types";

// Firebase
import { withFirebase } from "../Firebase";

// Components
import RenderAvatar from "../RenderAvatar";

// Material UI
// import styles from "../../util/styles";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CardMedia from "@material-ui/core/CardMedia";

const styles = theme => ({
  paper: {
    padding: theme.spacing(3, 2),
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: "inline"
  },
  list: {
    width: "100%",
    // maxWidth: 800,
    // overflow: "auto",
    backgroundColor: theme.palette.background.paper
  },
  tabs: {
    flexGrow: 1
  },
  media: {
    height: 75,
    width: 75,
    padding: theme.spacing(1, 2)
  }
});

class ChatList extends Component {
  state = {
    tab: 0
  };

  componentWillMount() {
    // Load chat history.
    this.props.onSetChatsLoading();
    const getUserChatsCallable = this.props.firebase.getUserChatsCallable();
    getUserChatsCallable()
      .then(result => {
        const { sellerChats, senderChats } = result.data;

        this.props.onSetChats(sellerChats, senderChats);
      })
      .catch(error => console.log(error));

    // Grab GCM device token.
    // this.props.firebase.saveMessagingDeviceToken();
  }

  handleClick = chat => () => {
    const { chatId } = chat;
    this.props.history.push(`/chat/${chatId}`);
  };

  handleChange = (event, newTab) => this.setState({ tab: newTab });

  renderSellerChat = (chat, index, length) => {
    const { classes } = this.props;
    const {
      title,
      sellerPhotoUrl,
      sellerUsername,
      chatId,
      photos,
      location,
      updatedAt
    } = chat;

    return (
      <React.Fragment key={chatId}>
        <ListItem alignItems="flex-start" onClick={this.handleClick(chat)}>
          <CardMedia
            component="img"
            className={classes.media}
            image={
              photos[0]
                ? photos[0]
                : "https://firebasestorage.googleapis.com/v0/b/lettuscamp.appspot.com/o/images%2Fcamping-tent.png?alt=media&token=79a1a4b6-f3e2-4ba9-9b31-d2767d6f88be"
            }
            title={title}
          />
          <ListItemText
            primary={fmtName(sellerUsername)}
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {title}
                </Typography>
                <br />
                {updatedAt &&
                  `${formatDistanceToNow(updatedAt._seconds * 1000)} ago`}
              </React.Fragment>
            }
          />
          <ListItemAvatar>
            <RenderAvatar username={sellerUsername} photoUrl={sellerPhotoUrl} />
          </ListItemAvatar>
        </ListItem>
        {index !== length - 1 && <Divider variant="inset" component="li" />}
      </React.Fragment>
    );
  };

  renderSenderChat = (chat, index, length) => {
    const { classes } = this.props;
    const {
      title,
      senderPhotoUrl,
      senderUsername,
      chatId,
      photos,
      location,
      updatedAt
    } = chat;

    return (
      <React.Fragment key={chatId}>
        <ListItem alignItems="flex-start" onClick={this.handleClick(chat)}>
          <CardMedia
            component="img"
            className={classes.media}
            image={
              photos[0]
                ? photos[0]
                : "https://firebasestorage.googleapis.com/v0/b/lettuscamp.appspot.com/o/images%2Fcamping-tent.png?alt=media&token=79a1a4b6-f3e2-4ba9-9b31-d2767d6f88be"
            }
            title={title}
          />
          <ListItemText
            primary={fmtName(senderUsername)}
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {title}
                </Typography>
                <br />
                {updatedAt &&
                  `${formatDistanceToNow(updatedAt._seconds * 1000)} ago`}
              </React.Fragment>
            }
          />
          <ListItemAvatar>
            <RenderAvatar username={senderUsername} photoUrl={senderPhotoUrl} />
          </ListItemAvatar>
        </ListItem>
        {index !== length - 1 && <Divider variant="inset" component="li" />}
      </React.Fragment>
    );
  };

  render() {
    const { sellerChats, senderChats, loadingChats, classes } = this.props;
    const { tab } = this.state;

    return (
      <React.Fragment>
        {loadingChats ? (
          <LinearProgress color="secondary" />
        ) : (
          <React.Fragment>
            <Paper className={classes.tabs}>
              <Tabs
                value={tab}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                {/* <Tab label="All" /> */}
                {/* <Tab label="Selling " />
                <Tab label="Buying" />
                 */}
                <Tab label="From Hosts" />
                <Tab label="From Campers" />
                {/* <Tab label="Blocked" /> */}
              </Tabs>
            </Paper>

            <List className={classes.list}>
              {// Render all chats and/or chats campers to us
              tab === 1 &&
                senderChats.map((chat, index) =>
                  this.renderSenderChat(chat, index, senderChats.length)
                )}
              {// Render all chats or and/or chats from us to the host
              tab === 0 &&
                sellerChats.map((chat, index) =>
                  this.renderSellerChat(chat, index, sellerChats.length)
                )}
            </List>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  sellerChats: state.chatState.sellerChats,
  senderChats: state.chatState.senderChats,
  loadingChats: state.chatState.loadingChats
});

const mapDispatchToProps = dispatch => ({
  onSetChatsLoading: () => dispatch({ type: CHATS_LOADING }),
  onSetChats: (sellerChats, senderChats) =>
    dispatch({ type: CHATS_SET, sellerChats, senderChats }),
  onClearChats: () => dispatch({ type: CHATS_CLEAR })
});

export default compose(
  withStyles(styles),
  withFirebase,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ChatList);
