import React, { Component } from "react";

// Components
import Share from "../Share";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import styles from "../../util/styles";

class ShareListingButton extends Component {
  state = {
    show: false,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    if (this.timerHandle) clearTimeout(this.timerHandle);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.timerHandle = setTimeout(() => {
        this.setState({ show: false });
      }, 500);
    }
  };

  handleClick = () => {
    this.setState((state) => ({ show: !state.show }));
  };

  render() {
    const { listingUid, title, classes } = this.props;
    const { show } = this.state;

    return (
      <React.Fragment>
        <Button
          size="small"
          // color="secondary"
          className={classes.button}
          onClick={this.handleClick}
          ref={this.setWrapperRef}
        >
          Share
        </Button>
        {show && (
          // TODO: put url in env variable
          <Share
            shareUrl={`https://app.lettuscamp.com/listings/${listingUid}`}
            title={title}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ShareListingButton);
