import React, { Component, Fragment } from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { LISTING_SET, LISTING_RESET } from "../../redux/types";

// Firebase
import { withFirebase } from "../Firebase";

// Components
import FavoritesIconButton from "../Favorites/FavoritesIconButton";
import ShareListingButton from "./ShareListingButton";
import EditListingButton from "./EditListingButton";
import DeleteListingButton from "./DeleteListingButton";
import CloseListingButton from "./CloseListingButton";
import ListingDates from "./ListingDates";
import ListingPrice from "./ListingPrice";
import SendMessageFab from "../Chat/SendMessageFab";
import LocationChip from "./LocationChip";
import Place from "../Place";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
// import styles from "../../util/styles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import LinearProgress from "@material-ui/core/LinearProgress";
import Card from "@material-ui/core/Card";
import styles from "../../util/styles";

const additionalStyles = theme => ({
  ...styles(theme),
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)"
  },
  // media: {
  //   height: 140
  //   height: "auto"
  // }
  price: {
    color: theme.palette.primary.dark
  },
  paper: {
    // marginTop: theme.spacing(4),
    // marginBottom: theme.spacing(0),
    margin: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  title: {
    // marginTop: theme.spacing(4),
    // marginBottom: theme.spacing(0),
    // margin: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  }
});

class ListingDetail extends Component {
  state = {
    loading: false
    // listing: null
  };

  componentDidMount() {
    this.setState({ loading: true });
    const { uid } = this.props.match.params;
    this.onListenForListingDetail(uid);
  }

  onListenForListingDetail(uid) {
    this.unsubscribe = this.props.firebase.listing(uid).onSnapshot(doc => {
      const listing = doc.data();
      this.props.onSetListing(listing);
      this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  handleDelete = value => {
    if (value === "cancel") {
      return;
    } else if (value === "delete") {
      // Delete the listing
      const { uid } = this.props.match.params;

      this.props.firebase
        .listing(uid)
        .delete()
        .then(() => {
          this.props.history.push("/");
        });
    }
  };

  handleClose = value => {
    if (value === "cancel") {
      return;
    } else if (value === "close") {
      // Mark listing as closed
      const { uid } = this.props.match.params;
      const { closed } = this.props;

      this.props.firebase
        .listing(uid)
        .set({ closed: !closed }, { merge: true });
    }
  };

  getGridListCols() {
    if (isWidthUp("lg", this.props.width)) {
      return 4.5;
    }
    if (isWidthUp("md", this.props.width)) {
      return 3.5;
    }
    if (isWidthUp("sm", this.props.width)) {
      return 2.5;
    }
    return 1.5;
  }

  render() {
    const { loading } = this.state;
    const {
      classes,
      authUser,
      startDate,
      endDate,
      datesValid,
      files,
      imagePreviewUrls,
      title,
      listingType,
      location,
      description,
      price,
      userId,
      photos,
      closed,
      chatLoading
    } = this.props;
    const { uid } = this.props.match.params;

    return (
      <Fragment>
        {(loading || chatLoading) && <LinearProgress color="secondary" />}
        {title && (
          <Container component="main" maxWidth="md">
            {!loading && authUser && authUser.uid === userId && (
              // Show delete, close, edit buttons when owner.
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Grid item xs={12} md={6}>
                  <ButtonGroup
                    fullWidth
                    aria-label="full width outlined button group"
                  >
                    <DeleteListingButton
                      listingUid={uid}
                      handleDelete={this.handleDelete}
                    />
                    <CloseListingButton
                      listingUid={uid}
                      handleClose={this.handleClose}
                      closed={closed}
                    />
                    <EditListingButton listingUid={uid} />
                  </ButtonGroup>
                </Grid>
              </Grid>
            )}

            <Grid
              container
              justify="flex-start"
              // alignItems="center"
              alignItems="baseline"
              spacing={2}
            >
              {!loading && (
                <Fragment>
                  {/* Photos */}
                  <Grid item xs={12}>
                    <GridList
                      className={classes.gridList}
                      cols={this.getGridListCols()}
                    >
                      {photos.length > 0 ? (
                        photos.map((image, index) => (
                          <GridListTile rows={1.5} key={index}>
                            <img src={image} alt="something TODO" />
                          </GridListTile>
                        ))
                      ) : (
                        <GridListTile rows={1.5} key={1}>
                          <img
                            src={
                              "https://firebasestorage.googleapis.com/v0/b/lettuscamp.appspot.com/o/images%2Fcamping-tent.png?alt=media&token=79a1a4b6-f3e2-4ba9-9b31-d2767d6f88be"
                            }
                            alt="something TODO"
                          />
                        </GridListTile>
                      )}
                    </GridList>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    // justify="flex-end"
                    justify="space-between"
                    alignItems="baseline"
                  >
                    <Grid item xs={2}>
                      <ShareListingButton listingUid={uid} title={title} />
                    </Grid>
                    <Grid item xs={2}>
                      <FavoritesIconButton listingUid={uid} />
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                      <ListingPrice price={price} className={classes.price} />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      className={classes.title}
                      variant="h5"
                      component="h3"
                      color="textSecondary"
                    >
                      {title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LocationChip location={location} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* <Typography className={classes.headings}>
                      Availability >
                    </Typography> */}
                    <ListingDates startDate={startDate} endDate={endDate} />
                  </Grid>

                  {description && (
                    <Grid item xs={12} sm={6}>
                      <Card>
                        <Typography className={classes.headings}>
                          Details
                        </Typography>
                        <Typography
                          className={classes.paper}
                          variant="body2"
                          component="p"
                          color="textSecondary"
                        >
                          {description}
                        </Typography>
                      </Card>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <Place />
                  </Grid>
                </Fragment>
              )}
            </Grid>

            {((!loading && !authUser) ||
              (!loading && authUser && authUser.uid !== userId)) && (
              <Fragment>
                <Box p={5} />
                <SendMessageFab listingId={uid} sellerId={userId} />
              </Fragment>
            )}
          </Container>
        )}

        {!loading && !title && (
          <Typography
            className={classes.paper}
            variant="h5"
            component="h3"
            color="textSecondary"
          >
            Something happened. We're looking into it.
          </Typography>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  startDate: state.listingState.startDate,
  endDate: state.listingState.endDate,
  datesValid: state.listingState.datesValid,
  files: state.listingState.files,
  imagePreviewUrls: state.listingState.imagePreviewUrls,
  title: state.listingState.title,
  listingType: state.listingState.listingType,
  location: state.listingState.location,
  description: state.listingState.description,
  price: state.listingState.price,
  userId: state.listingState.userId,
  photos: state.listingState.photos,
  closed: state.listingState.closed,
  chatLoading: state.chatState.loading
});

const mapDispatchToProps = dispatch => ({
  onSetListing: listing => dispatch({ type: LISTING_SET, listing }),
  onResetListing: () => dispatch({ type: LISTING_RESET })
});

export default compose(
  withStyles(additionalStyles),
  withRouter,
  withWidth(),
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(ListingDetail);
