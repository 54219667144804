import React from "react";
import Listings, { AddListingFab } from "../components/Listings";
import IntroBanner from "../components/IntroBanner";
import Box from "@material-ui/core/Box";

export default () => (
  <React.Fragment>
    <IntroBanner />
    <Listings />
    <Box p={5} />
    <AddListingFab />
  </React.Fragment>
);
