import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";

import fmtName from "../util/fmtName";

// Forms
import ChangePasswordForm from "./ChangePassword";

// Components
import {
  withAuthorization,
  withEmailVerification
} from "../components/Session";

import RenderAvatar from "../components/RenderAvatar";

const AccountPage = ({ authUser }) => (
  <Fragment>
    <h1>My Account</h1>

    <RenderAvatar
      username={authUser.displayName}
      photoUrl={authUser.photoURL}
    />

    <p>Name: {fmtName(authUser.displayName)}</p>
    <p>Email: {authUser.email}</p>

    {authUser.providerData.map(profile => (
      <Fragment key={profile.uid}>
        <p>{`Sign-in provider: ${profile.providerId}`}</p>
        {profile.providerId === "password" && <ChangePasswordForm />}
      </Fragment>
    ))}
  </Fragment>
);

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

const condition = authUser => !!authUser;

export default compose(
  connect(mapStateToProps),
  withEmailVerification,
  withAuthorization(condition)
)(AccountPage);
