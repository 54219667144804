import React from "react";
import { Link } from "react-router-dom";

import { withFirebase } from "./Firebase";

// Material UI

import IconButton from "@material-ui/core/IconButton";
import SignOutIcon from "@material-ui/icons/ExitToApp";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1)
  }
}));

const SignOutButton = ({ firebase }) => {
  const classes = styles();

  return (
    <IconButton onClick={firebase.doSignOut} component={Link} to="/">
      <SignOutIcon className={classes.button} />
      <Typography>Sign Out</Typography>
    </IconButton>
  );
};

export default withFirebase(SignOutButton);
