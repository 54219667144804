import React, { Component } from "react";
import { Link } from "react-router-dom";

// Material UI
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  button: {
    margin: theme.spacing(1)
  }
});

class EditListingButton extends Component {
  render() {
    const { classes, listingUid } = this.props;

    return (
      <Button
        variant="outlined"
        className={classes.button}
        startIcon={<EditIcon />}
        component={Link}
        to={`/listings/${listingUid}/edit`}
      >
        Edit
      </Button>
    );
  }
}

export default withStyles(styles)(EditListingButton);
