import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";

// Components
import {
  withAuthorization,
  withEmailVerification
} from "../components/Session";

import NewListing from "../components/Listings/NewListing";

class NewListingPage extends React.Component {
  render() {
    return <NewListing />;
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

const condition = authUser => !!authUser;

// export default withAuthorization(condition)(withEmailVerification(AccountPage));
export default compose(
  connect(mapStateToProps),
  withEmailVerification,
  withAuthorization(condition)
)(NewListingPage);
