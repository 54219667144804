// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// Firebase products
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import "firebase/messaging";
import "firebase/performance";

// Config for our Firebase project.
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

class Firebase {
  constructor() {
    firebase.initializeApp(firebaseConfig);

    firebase.performance();

    this.auth = firebase.auth();

    this.firestore = firebase.firestore();

    this.storage = firebase.storage();

    this.functions = firebase.functions();

    this.googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    this.googleAuthProvider.addScope("email");

    this.facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
    this.facebookAuthProvider.addScope("email");

    if (firebase.messaging.isSupported()) {
      this.messaging = firebase.messaging();
      this.messaging.usePublicVapidKey(process.env.REACT_APP_WEB_PUSH_CERT);
    }
  }

  // Storage Ref
  storageRef = () => this.storage.ref();

  // Auth API
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGooglePopup = () =>
    this.auth.signInWithPopup(this.googleAuthProvider);

  doSignInWithFacebookPopup = () =>
    this.auth.signInWithPopup(this.facebookAuthProvider);

  // Redirect signin - preferred for mobile.
  googleSignInWithRedirect = () =>
    this.auth.signInWithRedirect(this.googleAuthProvider);

  getRedirectResult = () => this.auth.getRedirectResult();

  facebookSignInWithRedirect = () =>
    this.auth.signInWithRedirect(this.facebookAuthProvider);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
    });

  doSignOut = () => this.auth.signOut();

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  doSendPasswordResetEmail = email => this.auth.sendPasswordResetEmail(email);

  // Helper function
  serverTimestamp = () => firebase.firestore.FieldValue.serverTimestamp();

  // Chat API
  createChatCallable = () => this.functions.httpsCallable("createChat");
  chat = chatId => this.firestore.doc(`chats/${chatId}`);
  getUserChatsCallable = () => this.functions.httpsCallable("getUserChats");

  // User API
  user = uid => this.firestore.doc(`users/${uid}`);
  users = () => this.firestore.collection(`users`);

  // Favorites
  addToFavorites = (uid, listingId) =>
    this.firestore.doc(`users/${uid}`).update({
      favorites: firebase.firestore.FieldValue.arrayUnion(listingId)
    });

  removeFromFavorites = (uid, listingId) =>
    this.firestore.doc(`users/${uid}`).update({
      favorites: firebase.firestore.FieldValue.arrayRemove(listingId)
    });

  // Message API - TODO: remove, deprecated.
  message = uid => this.firestore.doc(`messages/${uid}`);
  messages = () => this.firestore.collection(`messages`);

  // Listings API
  listing = uid => this.firestore.doc(`listings/${uid}`);
  listings = () => this.firestore.collection(`listings`);

  // Notificaitons API
  getNotificationsForRecipient = recipientId =>
    this.firestore
      .collection(`notifications`)
      .where("recipientId", "==", recipientId)
      // .where("read", "==", false)
      .orderBy("createdAt", "desc");

  getUnreadNotificationsForChat = (recipientId, chatId) =>
    this.firestore
      .collection(`notifications`)
      .where("recipientId", "==", recipientId)
      .where("chatId", "==", chatId)
      .where("read", "==", false);

  getUnreadNotificationsForRecipient = recipientId =>
    this.firestore
      .collection(`notifications`)
      .where("recipientId", "==", recipientId)
      .where("read", "==", false);

  markNotificationRead = notificationId =>
    this.firestore
      .doc(`notifications/${notificationId}`)
      .set({ read: true }, { merge: true });

  // Firebase Cloud Messaging API

  // Saves the messaging device token to the datastore.
  saveMessagingDeviceToken = () =>
    firebase.messaging.isSupported() &&
    this.messaging
      .getToken()
      .then(currentToken => {
        if (currentToken) {
          console.log("Got FCM device token:", currentToken);
          // Saving the Device Token to the datastore.
          this.sendTokenToServer(currentToken);
        } else {
          // Need to request permissions to show notifications.
          this.requestNotificationsPermissions();
        }
      })
      .catch(error => {
        console.error("Unable to get messaging token.", error);
      });

  sendTokenToServer = currentToken =>
    this.firestore.doc(`users/${firebase.auth().currentUser.uid}`).update({
      fcmTokens: firebase.firestore.FieldValue.arrayUnion(currentToken)
    });

  // Callback fired if Instance ID token is updated.
  onTokenRefresh = () =>
    this.messaging.onTokenRefresh(() => {
      this.messaging
        .getToken()
        .then(refreshedToken => {
          console.log("Token refreshed:", refreshedToken);

          // Send Instance ID token to app server.
          this.sendTokenToServer(refreshedToken);
        })
        .catch(err => {
          console.log("Unable to retrieve refreshed token ", err);
        });
    });

  // Requests permission to show notifications.
  requestNotificationsPermissions = () => {
    console.log("Requesting notifications permission...");
    this.messaging
      .requestPermission()
      .then(() => {
        // Notification permission granted.
        this.saveMessagingDeviceToken();
      })
      .catch(error => {
        console.error("Unable to get permission to notify.", error);
      });
  };
}

export default Firebase;
