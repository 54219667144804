import React, { Component, Fragment } from "react";

// Material UI
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import UndoIcon from "@material-ui/icons/UndoOutlined";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
  button: {
    margin: theme.spacing(1)
  }
});

class CloseListingButton extends Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = value => {
    this.setState({ open: false });
    this.props.handleClose(value);
  };

  render() {
    const { classes, closed } = this.props;
    const { open } = this.state;

    return (
      <Fragment>
        <Button
          variant="outlined"
          onClick={this.handleClickOpen}
          className={classes.button}
          startIcon={(!closed && <CheckIcon />) || (closed && <UndoIcon />)}
        >
          {(!closed && "Hide Campsite") || (closed && "Show Campsite")}
        </Button>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {(!closed && "Hide Campsite?") || (closed && "Show Campsite?")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {(!closed &&
                "People will not be able to contact you about it.") ||
                (closed && "People can find it and contact you.")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleClose("cancel")}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>

            <Button onClick={() => this.handleClose("close")} color="primary">
              {(!closed && "Hide Campsite") || (closed && "Show Campsite")}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

export default withStyles(styles)(CloseListingButton);
