import React, { Component, Fragment } from "react";
import { compose } from "recompose";

// Material UI
import { withStyles } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

// Lettus Logo
import LettusLogo from "../LettusIcon/yesForwardGreenxxhdpi.png";

const styles = theme => ({
  root: {
    maxWidth: 345
  },
  lettusIcon: {
    height: 50,
    width: 50
  },
  header: {
    color: theme.palette.primary.dark
  }
});

class IntroBanner extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={1}>
          <img src={LettusLogo} className={classes.lettusIcon} alt="logo" />
        </Grid>
        <Grid item xs={8}>
          <CardContent>
            <Typography
              gutterBottom
              align="center"
              variant="h5"
              className={classes.header}
              component="h2"
            >
              LettUsCamp
            </Typography>
            <Typography
              align="center"
              variant="body2"
              fontStyle="italic"
              color="textSecondary"
              component="p"
            >
              Share camps rather than book camps
              {/* Find camps. Make friends. Save cost. Travel freedom. */}
            </Typography>
          </CardContent>
        </Grid>
        {/* <Grid item>
          <Typography
            align="center"
            variant="body2"
            fontStyle="italic"
            color="textSecondary"
            component="p"
          >
            Find camps. Make friends. Save cost. Travel freedom.
          </Typography>
        </Grid> */}
      </Grid>
    );
  }
}

export default compose(withStyles(styles))(IntroBanner);
