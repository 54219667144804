import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";

// Components
import { withAuthorization } from "../components/Session";
import Favorites from "../components/Favorites/Favorites";
import { AddListingFab } from "../components/Listings";

// Material UI
import Box from "@material-ui/core/Box";

class FavoritesPage extends React.Component {
  render() {
    return (
      <Fragment>
        <Favorites />
        <Box p={4} />
        <AddListingFab />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

const condition = authUser => !!authUser;

// export default withAuthorization(condition)(withEmailVerification(AccountPage));
export default compose(
  connect(mapStateToProps),
  withAuthorization(condition)
)(FavoritesPage);
