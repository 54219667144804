import React from "react";
import { Link } from "react-router-dom";
import { compose } from "recompose";

// Material UI
import Fab from "@material-ui/core/Fab";
import CameraAlt from "@material-ui/icons/CameraAlt";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  fab: {
    // margin: 0,
    top: "auto",
    left: "auto",
    // left: theme.spacing(2),
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    position: "fixed",
    margin: theme.spacing(2),
    // "max-width": 300
    backgroundColor: theme.palette.secondary.special
  },
  cameraAltIcon: {
    marginRight: theme.spacing(1)
  }
});

class AddListingFab extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Fab
          variant="extended"
          className={classes.fab}
          aria-label="New Listing"
          color="secondary"
          component={Link}
          to="/newlisting"
        >
          <CameraAlt className={classes.cameraAltIcon} />
          Share your campsite
        </Fab>
      </React.Fragment>
    );
  }
}

export default compose(withStyles(styles))(AddListingFab);
