import React, { Component, Fragment } from "react";
import { compose } from "recompose";

import formatDistanceToNow from "date-fns/formatDistanceToNow";

// Redux
import { connect } from "react-redux";

// Components
import RenderAvatar from "../RenderAvatar";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Box from "@material-ui/core/Box";
import fmtName from "../../util/fmtName";

const styles = theme => ({
  root: {
    width: "100%",
    // maxHeight: 500,
    overflow: "auto"
    // backgroundColor: theme.palette.background.paper
  },
  message: {
    textAlign: "right",
    marginLeft: "auto",
    marginRight: 0
  },
  inline: {
    display: "inline"
  }
});

class MessageList extends Component {
  messagesEnd = null;

  scrollToBottom = () => {
    this.messagesEnd && this.messagesEnd.scrollIntoView(); //{ behavior: "smooth" }
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  renderMessage = message => {
    const { authUser, chat, classes } = this.props;

    const [photoUrl, username] =
      message.userId === chat.senderId
        ? [chat.senderPhotoUrl, fmtName(chat.senderUsername)]
        : [chat.sellerPhotoUrl, fmtName(chat.sellerUsername)];

    return authUser.uid === message.userId ? (
      <ListItem key={message.uid}>
        <ListItemText
          className={classes.message}
          primary={message.text}
          secondary={
            message.createdAt &&
            `${formatDistanceToNow(message.createdAt.toDate())} ago`
          }
        />
      </ListItem>
    ) : (
      <ListItem alignItems="flex-start" key={message.uid}>
        <ListItemAvatar>
          <RenderAvatar username={username} photoUrl={photoUrl} />
        </ListItemAvatar>
        <ListItemText
          primary={message.text}
          secondary={
            message.createdAt &&
            `${formatDistanceToNow(message.createdAt.toDate())} ago`
          }
        />
      </ListItem>
    );
  };

  render() {
    const { loading, chat, authUser, messages, classes } = this.props;

    return (
      !loading &&
      chat && (
        <List className={classes.root}>
          <Box p={7} />

          {messages.map(message => this.renderMessage(message))}

          <div
            style={{ float: "left", clear: "both" }}
            ref={el => {
              this.messagesEnd = el;
            }}
          ></div>
          <Box p={5} />
        </List>
      )
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  loading: state.chatState.loading,
  chat: state.chatState.chat,
  messages: state.chatState.messages
});

export default compose(
  withStyles(styles),
  // withFirebase,
  connect(mapStateToProps, null)
)(MessageList);
