import { combineReducers } from "redux";
import sessionReducer from "./session";
import userReducer from "./user";
import messageReducer from "./message";
import listingsReducer from "./listings";
import listingReducer from "./listing";
import chatReducer from "./chat";

const rootReducer = combineReducers({
  sessionState: sessionReducer,
  userState: userReducer,
  messageState: messageReducer,
  listingsState: listingsReducer,
  listingState: listingReducer,
  chatState: chatReducer
});

export default rootReducer;
