import React, { Component, Fragment } from "react";

// Material UI
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
  button: {
    margin: theme.spacing(1)
  }
});

class DeleteListingButton extends Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = value => {
    this.setState({ open: false });
    this.props.handleDelete(value);
  };

  render() {
    const { classes } = this.props;
    const { open } = this.state;

    return (
      <Fragment>
        <Button
          variant="outlined"
          onClick={this.handleClickOpen}
          className={classes.button}
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete this Listing?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Delete this Listing forever. Once you do this it cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleClose("cancel")}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
            <Button onClick={() => this.handleClose("delete")} color="primary">
              Delete Forever
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

export default withStyles(styles)(DeleteListingButton);
