const styles = theme => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1)
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.secondary.special
  },
  facebookButton: {
    margin: theme.spacing(3, 0, 2),
    background: "#4267b2",
    color: "white"
  },
  googleButton: {
    margin: theme.spacing(3, 0, 2),
    background: "white"
  },
  button: {
    color: theme.palette.secondary.dark
  },
  headings: {
    margin: theme.spacing(1),
    color: theme.palette.primary.dark
  }
});

export default styles;
