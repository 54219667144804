import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import fmtName from "../../util/fmtName";

// Redux
import { connect } from "react-redux";

// Firebase
import { withFirebase } from "../Firebase";

// Components
import RenderAvatar from "../RenderAvatar";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import CardMedia from "@material-ui/core/CardMedia";

const styles = theme => ({
  paper: {
    padding: theme.spacing(3, 2),
    backgroundColor: theme.palette.background.paper
  },
  read: {
    opacity: 0.4
  },
  unread: {
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: "inline"
  },
  avatar: {
    padding: theme.spacing(2, 2)
  },
  list: {
    width: "100%",
    // maxWidth: 800,
    // overflow: "auto",
    backgroundColor: theme.palette.background.paper
  },
  media: {
    height: 75,
    width: 75,
    padding: theme.spacing(1, 2)
  }
});

class NotificationsList extends Component {
  state = {
    notifications: [],
    loading: true
  };

  componentWillMount() {
    const { authUser } = this.props;

    // Listen for notifications.
    this.unsubscribe = this.props.firebase
      .getNotificationsForRecipient(authUser.uid)
      .limit(50)
      .onSnapshot(querySnapshot => {
        const notifications = [];
        querySnapshot.forEach(doc => {
          notifications.push({ ...doc.data(), uid: doc.id });
        });
        this.setState({
          notifications,
          loading: false
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  handleClick = chatId => () => {
    this.props.history.push(`/chat/${chatId}`);
  };

  render() {
    const { loading, notifications } = this.state;
    const { classes } = this.props;

    return (
      <React.Fragment>
        {loading && <LinearProgress color="secondary" />}

        {!loading && (
          <React.Fragment>
            <List className={classes.list}>
              {notifications.map(notification => (
                <React.Fragment key={notification.uid}>
                  <ListItem
                    alignItems="flex-start"
                    onClick={this.handleClick(notification.chatId)}
                    className={notification.read ? classes.read : ""}
                  >
                    <ListItemAvatar className={classes.avatar}>
                      <RenderAvatar
                        username={notification.senderName}
                        photoUrl={notification.senderPhotoUrl}
                      />
                    </ListItemAvatar>

                    <ListItemText
                      primary={notification.text}
                      secondary={
                        <React.Fragment>
                          {/* <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                            >
                            {notification.text}
                          </Typography> */}
                          {notification.title}
                          <br />
                          {notification.createdAt &&
                            `${formatDistanceToNow(
                              notification.createdAt.toDate()
                            )} ago`}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              ))}
            </List>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

export default compose(
  withStyles(styles),
  withFirebase,
  withRouter,
  connect(mapStateToProps, null)
)(NotificationsList);
