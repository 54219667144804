import React, { Component } from "react";

import { withFirebase } from "../components/Firebase";

// TODO: secure data access to all users for admins only.
// https://firebase.google.com/docs/firestore/solutions/role-based-access

class Admin extends Component {
  state = {
    loading: false,
    users: []
  };

  listener;

  componentDidMount() {
    this.setState({ loading: true });

    this.listener = this.props.firebase.users().onSnapshot(
      usersSnapshot => {
        const users = usersSnapshot.docs.map(user => ({
          uid: user.id,
          ...user.data()
        }));

        this.setState({
          users,
          loading: false
        });
      },
      err => console.log(err)
    );
  }

  componentWillUnmount() {
    this.listener();
  }

  renderUsers() {
    return (
      <ul>
        {this.state.users.map(user => (
          <li key={user.uid}>
            <span>
              <strong> ID:</strong> {user.uid}
            </span>
            <span>
              <strong> Email:</strong> {user.email}
            </span>
            <span>
              <strong> Username:</strong> {user.username}
            </span>
          </li>
        ))}
      </ul>
    );
  }

  render() {
    const { loading } = this.state;

    return (
      <div>
        <h1>Admin</h1>
        {loading && <div>Loading...</div>}
        {this.renderUsers()}
      </div>
    );
  }
}

export default withFirebase(Admin);
