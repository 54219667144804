import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";

// Components
import EditListing from "../components/Listings/EditListing";
import {
  withAuthorization,
  withEmailVerification
} from "../components/Session";

class EditListingPage extends React.Component {
  render() {
    return <EditListing />;
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

const condition = authUser => !!authUser;

export default compose(
  connect(mapStateToProps),
  withEmailVerification,
  withAuthorization(condition)
)(EditListingPage);
