import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";

// Firebase
import { withFirebase } from "./Firebase";

// Components
import SignOutButton from "./SignOut";
import RenderAvatar from "./RenderAvatar";

// Material-UI
import { makeStyles, fade } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import ExternLink from "@material-ui/core/Link";

// Icons
import FavoriteIcon from "@material-ui/icons/Favorite";
import MessageIcon from "@material-ui/icons/Message";
import AccountIcon from "@material-ui/icons/AccountCircleOutlined";
import MoreIcon from "@material-ui/icons/MoreVert";
import MenuIcon from "@material-ui/icons/Menu";
import LandscapeIcon from "@material-ui/icons/Landscape";
import NotificationsIcon from "@material-ui/icons/Notifications";
import HomeIcon from "@material-ui/icons/Home";
import DocIcon from "@material-ui/icons/Description";
import SecurityIcon from "@material-ui/icons/Security";
import AboutIcon from "@material-ui/icons/Info";
import SearchIcon from "@material-ui/icons/Search";
import SignInIcon from "@material-ui/icons/PersonAdd";

// Lettus Logo
import LettusLogo from "./LettusIcon/yesForwardGreenxxhdpi.png";
// import { ReactComponent as LettusLogo } from "./LettusIcon/lettusIcon.svg";

const useStyles = makeStyles(theme => ({
  lettusIcon: {
    height: 35,
    width: 35
  },
  label: {
    flexDirection: "column"
  },
  typography: {
    fontSize: 12
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(1)
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  selected: {
    // color: theme.palette.secondary.dark
    color: theme.palette.primary.main
  },
  deselected: {
    // color: theme.palette.primary.main
    color: "default"
  },
  signInButton: {
    color: theme.palette.primary.main
  }
}));

const Navbar = ({ authUser, history, firebase }) => {
  const classes = useStyles();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = React.useState(0);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const homeIconColor = () =>
    history.location.pathname === "/" ? classes.selected : classes.deselected;

  const messagesIconColor = classes =>
    history.location.pathname === "/chat"
      ? classes.selected
      : classes.deselected;

  const myListingsIconColor = () =>
    history.location.pathname === "/mylistings"
      ? classes.selected
      : classes.deselected;

  const notificationsIconColor = () =>
    history.location.pathname === "/notifications"
      ? classes.selected
      : classes.deselected;

  const favoritesIconColor = () =>
    history.location.pathname === "/favorites"
      ? classes.selected
      : classes.deselected;

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const privacyPolicyMenuItem = (
    <MenuItem onClick={handleMobileMenuClose}>
      <ExternLink
        href="https://www.lettuscamp.com/privacy"
        target="_blank"
        rel="noopener"
      >
        <IconButton aria-label="Privacy Policy">
          <SecurityIcon className={classes.menuButton} />
          <Typography>Privacy Policy</Typography>
        </IconButton>
      </ExternLink>
    </MenuItem>
  );

  const termsConditionsMenuItem = (
    <MenuItem onClick={handleMobileMenuClose}>
      <ExternLink
        href="https://www.lettuscamp.com/terms"
        target="_blank"
        rel="noopener"
      >
        <IconButton aria-label="Terms and Conditions">
          <DocIcon className={classes.menuButton} />
          <Typography>Terms and Conditions</Typography>
        </IconButton>
      </ExternLink>
    </MenuItem>
  );

  const aboutUsMenuItem = (
    <MenuItem onClick={handleMobileMenuClose}>
      <ExternLink
        href="https://www.lettuscamp.com/about"
        target="_blank"
        rel="noopener"
      >
        <IconButton aria-label="About LettUsCamp">
          <AboutIcon className={classes.menuButton} />
          <Typography>About LettUsCamp</Typography>
        </IconButton>
      </ExternLink>
    </MenuItem>
  );

  const signInMenuItem = (
    <MenuItem onClick={handleMobileMenuClose}>
      <Link to="/signin">
        <IconButton aria-label="Sign In">
          <SignInIcon className={classes.menuButton} />
          <Typography>Sign In</Typography>
        </IconButton>
      </Link>
    </MenuItem>
  );

  const favoritesMenuItem = (
    <MenuItem onClick={handleMobileMenuClose}>
      <Link to="/favorites">
        <IconButton aria-label="Favorites">
          <FavoriteIcon className={classes.menuButton} />
          <Typography>Favorites</Typography>
        </IconButton>
      </Link>
    </MenuItem>
  );

  React.useEffect(() => {
    if (authUser) {
      const unsubscribe = firebase
        .getUnreadNotificationsForRecipient(authUser.uid)
        .onSnapshot(querySnapshot => {
          let total = 0;
          querySnapshot.forEach(doc => {
            total += 1;
          });
          setNotifications(total);
        });

      return () => unsubscribe();
    } else {
      setNotifications(0);
    }
  });

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderLoggedOutMenu = (
    <Fragment>
      <IconButton
        color="primary"
        aria-label="show more"
        aria-controls={mobileMenuId}
        aria-haspopup="true"
        onClick={handleMobileMenuOpen}
      >
        {/* <MoreIcon /> */}
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        {signInMenuItem}
        {termsConditionsMenuItem}
        {privacyPolicyMenuItem}
        {aboutUsMenuItem}
      </Menu>
    </Fragment>
  );

  const renderUserMenu = authUser => (
    <Fragment>
      <IconButton
        color="primary"
        aria-label="show more"
        aria-controls={mobileMenuId}
        aria-haspopup="true"
        onClick={handleMobileMenuOpen}
      >
        {/* <MoreIcon /> */}
        {/* <MenuIcon /> */}
        <RenderAvatar
          username={authUser.displayName}
          photoUrl={authUser.photoURL}
        />
      </IconButton>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        {/* {favoritesMenuItem} */}
        <MenuItem onClick={handleMobileMenuClose}>
          <Link to="/account">
            <IconButton aria-label="Account">
              <AccountIcon className={classes.menuButton} />
              <Typography>Account</Typography>
            </IconButton>
          </Link>
        </MenuItem>
        <MenuItem onClick={handleMobileMenuClose}>
          <Link to="/mylistings">
            <IconButton aria-label="My Camps">
              <LandscapeIcon className={classes.menuButton} />
              <Typography>Your Camps</Typography>
            </IconButton>
          </Link>
        </MenuItem>
        <MenuItem onClick={handleMobileMenuClose}>
          <SignOutButton className={classes.menuButton} />
        </MenuItem>
        {termsConditionsMenuItem}
        {privacyPolicyMenuItem}
        {aboutUsMenuItem}
      </Menu>
    </Fragment>
  );

  const renderHomeIcon = (
    <Link to="/">
      <IconButton
        // color="inherit"
        aria-label="Explore"
        classes={{ label: classes.label }}
        className={homeIconColor(classes)}
      >
        {/* <HomeIcon className={classes.icon} /> */}
        {/* <img src={LettusLogo} className={classes.lettusIcon} alt="logo" /> */}
        <SearchIcon className={classes.icon} />
        <Typography className={classes.typography}>Explore</Typography>
      </IconButton>
    </Link>
  );

  const renderLettusHead = (
    // <Link to="/">
    // <IconButton
    //   // color="inherit"
    //   aria-label="Explore"
    //   classes={{ label: classes.label }}
    //   className={homeIconColor(classes)}
    // >
    <Fragment>
      <img src={LettusLogo} className={classes.lettusIcon} alt="logo" />

      {/* <Typography className={classes.typography}>LettusCamp</Typography> */}
    </Fragment>
    // </IconButton>
    // </Link>
  );

  const renderNotifications = (
    <Link to="/notifications">
      <IconButton
        aria-label="Notifications"
        classes={{ label: classes.label }}
        className={notificationsIconColor(classes)}
      >
        <Badge badgeContent={notifications} color="error">
          <NotificationsIcon className={classes.icon} />
        </Badge>
        <Typography className={classes.typography}>Notifications</Typography>
      </IconButton>
    </Link>
  );

  const renderChat = (
    <Link to="/chat">
      <IconButton
        aria-label="Show 5 new messages"
        classes={{
          label: classes.label
        }}
        // color={"primary"}
        // className={classes.deselected}
        className={messagesIconColor(classes)}
        // color={messagesIconColor(classes)}
      >
        {/* <Badge badgeContent={5} color="error"> */}
        <MessageIcon className={classes.icon} />
        {/* </Badge> */}
        <Typography className={classes.typography}>Messages</Typography>
      </IconButton>
    </Link>
  );

  const renderFavorites = (
    <Link to="/favorites">
      <IconButton
        aria-label="Favorites"
        classes={{ label: classes.label }}
        className={favoritesIconColor(classes)}
      >
        <FavoriteIcon className={classes.icon} />
        <Typography className={classes.typography}>Favorites</Typography>
      </IconButton>
    </Link>
  );

  const renderSignIn = (
    <Button component={Link} to="/signin" className={classes.signInButton}>
      Sign In
    </Button>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" color="inherit">
        <Toolbar>
          {/* {renderLettusHead} */}

          {authUser ? (
            <Fragment>
              {/* <Typography className={classes.title} variant="h6" noWrap>
                LettUsCamp
              </Typography> */}

              {/* <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
              </div> */}

              <div className={classes.grow} />

              {renderHomeIcon}
              {renderChat}
              {renderNotifications}
              {renderFavorites}

              <div className={classes.grow} />

              {renderUserMenu(authUser)}
            </Fragment>
          ) : (
            <Fragment>
              <div className={classes.grow} />

              {renderHomeIcon}
              {renderChat}
              {renderNotifications}
              {renderFavorites}

              {/* {renderSignIn} */}

              <div className={classes.grow} />

              {renderLoggedOutMenu}
            </Fragment>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps)
)(Navbar);
