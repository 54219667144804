import { USER_SET, USER_ADD_FAVORITE, USER_REMOVE_FAVORITE } from "../types";

const INITIAL_STATE = {
  // users: null
  user: null
};

// const applySetUsers = (state, action) => ({
//   ...state,
//   users: action.users
// });

// const applySetUser = (state, action) => ({
//   ...state,
//   users: {
//     ...state.users,
//     [action.uid]: action.user
//   }
// });

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // case USERS_SET: {
    //   return applySetUsers(state, action);
    // }
    case USER_SET: {
      return {
        user: action.user
      };
    }

    // TODO: these are mutating the state, which is intended
    //       behavior so that the favorites to not re-render
    //       (and cause the listing to dissapear), in case this
    //       is done by accident. Solution is to add a confirmation
    //       dialog.
    case USER_ADD_FAVORITE: {
      let { favorites } = state.user;
      if (favorites) {
        favorites.push(action.listingUid);
      } else {
        favorites = [action.listingUid];
      }
      return {
        ...state,
        user: {
          ...state.user,
          favorites
        }
      };
    }

    case USER_REMOVE_FAVORITE: {
      let index = state.user.favorites.findIndex(
        listingUid => listingUid === action.listingUid
      );
      const favorites = state.user.favorites;
      if (index > -1) {
        favorites.splice(index, 1);
      }

      return {
        ...state,
        user: {
          ...state.user,
          favorites
        }
      };
    }
    default:
      return state;
  }
};

export default userReducer;
