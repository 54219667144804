import React, { Component, Fragment } from "react";
import { compose } from "recompose";

// Redux
import { connect } from "react-redux";
import {
  LISTING_FILE_SET,
  LISTING_URL_SET,
  LISTING_IMAGES_RESET
} from "../../redux/types";

// Components
import { withFirebase } from "../Firebase";

// Material UI
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import CameraAlt from "@material-ui/icons/CameraAlt";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

// Load Image
import loadImage from "blueimp-load-image";
const MAX_WIDTH = 400;
const MAX_HEIGHT = 400;

const styles = theme => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  input: {
    display: "none"
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)"
  }
});

class ImageUploader extends Component {
  getGridListCols() {
    // if (isWidthUp("lg", this.props.width)) {
    //   return 4.5;
    // }
    // if (isWidthUp("md", this.props.width)) {
    //   return 3.5;
    // }
    // if (isWidthUp("sm", this.props.width)) {
    //   return 2.5;
    // }
    return 1.5;
  }

  onImageChange = event => {
    event.preventDefault();

    const { files } = event.target;

    for (let i = 0; i < files.length; i++) {
      loadImage.parseMetaData(files[i], data => {
        // default image orientation
        let orientation = 0;

        // if exif data available, update orientation
        if (data.exif) {
          orientation = data.exif.get("Orientation");
        }

        loadImage(
          files[i],
          canvas => {
            // Set the data URL
            const dataUrl = canvas.toDataURL("image/jpeg");
            this.props.onSetUrls(dataUrl);
          },
          {
            canvas: true, // activate auto fix orientation
            orientation: orientation,
            maxWidth: MAX_WIDTH,
            maxHeight: MAX_HEIGHT
          }
        );
      });
    }
  };

  componentWillUnmount() {
    this.props.onResetImages();
  }

  render() {
    const { classes } = this.props;
    const { files, imagePreviewUrls } = this.props;

    return (
      <Fragment>
        <Grid container justify="center">
          <Grid item>
            <input
              accept="image/*"
              className={classes.input}
              id="input-button-upload"
              multiple
              type="file"
              onChange={this.onImageChange}
            />
            <label htmlFor="input-button-upload">
              <Button
                component="span"
                variant="contained"
                color="default"
                className={classes.submit}
                startIcon={<CameraAlt />}
              >
                Add Images of Your Campsite
              </Button>
            </label>
          </Grid>
        </Grid>
        {imagePreviewUrls.length > 0 && (
          <GridList
            className={classes.gridList}
            cellHeight={200}
            cols={this.getGridListCols()}
          >
            {imagePreviewUrls.map((image, index) => (
              <GridListTile key={index}>
                <img src={image} alt="something TODO" />
              </GridListTile>
            ))}
          </GridList>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  files: state.listingState.files, // TODO: we don't need these anywhere in the app
  imagePreviewUrls: state.listingState.imagePreviewUrls
});

const mapDispatchToProps = dispatch => ({
  onSetFiles: file => dispatch({ type: LISTING_FILE_SET, file }), // TODO: not using files
  onSetUrls: imagePreviewUrl =>
    dispatch({ type: LISTING_URL_SET, imagePreviewUrl }),
  onResetImages: () => dispatch({ type: LISTING_IMAGES_RESET })
});

export default compose(
  withStyles(styles),
  withFirebase,
  withWidth(),
  connect(mapStateToProps, mapDispatchToProps)
)(ImageUploader);
