import React, { Component, Fragment } from "react";
import { compose } from "recompose";

// Redux
import { connect } from "react-redux";
import {
  LISTINGS_SET,
  LISTINGS_NEXT_SET,
  LISTINGS_LIMIT_SET
} from "../../redux/types";

// Material UI
import LinearProgress from "@material-ui/core/LinearProgress";

// Firebase
import { withFirebase } from "../Firebase";

// Components
import MyListingList from "./MyListingList";

class MyListings extends Component {
  state = {
    loading: false,
    lastDoc: null
  };

  handleScroll = () => {
    if (!this.state.loading) {
      const windowHeight =
        "innerHeight" in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight =
        Math.max(
          body.scrollHeight,
          body.offsetHeight,
          html.clientHeight,
          html.scrollHeight,
          html.offsetHeight
        ) * 0.8;
      const windowBottom = Math.round(windowHeight + window.pageYOffset);

      if (windowBottom >= docHeight) {
        this.setState({ loading: true });
        this.getNextListings();
      }
    }
  };

  componentDidMount() {
    if (!this.props.listings.length) {
      this.setState({ loading: true });
    }

    this.getListings();

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  getListings = () => {
    this.props.firebase
      .listings()
      .where("userId", "==", this.props.authUser.uid)
      .orderBy("createdAt", "desc")
      .limit(6)
      .get()
      .then(querySnapshot => {
        const listings = [];

        querySnapshot.forEach(doc => {
          listings.push({ ...doc.data(), uid: doc.id });
        });

        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        this.props.onSetListings(listings);
        this.setState({
          loading: false,
          lastDoc
        });
      });
  };

  getNextListings = () => {
    const { lastDoc } = this.state;

    if (lastDoc)
      this.props.firebase
        .listings()
        .where("userId", "==", this.props.authUser.uid)
        .orderBy("createdAt", "desc")
        .limit(4)
        .startAfter(lastDoc)
        .get()
        .then(querySnapshot => {
          const listings = [];

          querySnapshot.forEach(doc => {
            listings.push({ ...doc.data(), uid: doc.id });
          });

          const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

          this.props.onSetNextListings(listings);
          this.setState({
            loading: false,
            lastDoc
          });
        });
    else this.setState({ loading: false });
  };

  render() {
    const { listings } = this.props;

    const { loading } = this.state;

    return (
      <Fragment>
        {listings && (
          <MyListingList authUser={this.props.authUser} listings={listings} />
        )}
        {!listings && <div>You have no listings</div>}

        {loading && <LinearProgress color="secondary" />}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  listings: Object.keys(state.listingsState.listings || {}).map(key => ({
    ...state.listingsState.listings[key]
  })),
  limit: state.listingsState.limit
});

const mapDispatchToProps = dispatch => ({
  onSetListings: listings => dispatch({ type: LISTINGS_SET, listings }),
  onSetNextListings: listings =>
    dispatch({ type: LISTINGS_NEXT_SET, listings }),
  onSetListingsLimit: limit => dispatch({ type: LISTINGS_LIMIT_SET, limit })
});

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(MyListings);
