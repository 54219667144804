import React from "react";
import { Link } from "react-router-dom";

// Components
import FavoritesIconButton from "../Favorites/FavoritesIconButton";
import ShareListingButton from "./ShareListingButton";
import ListingDates from "./ListingDates";
import ListingPrice from "./ListingPrice";
import LocationChip from "./LocationChip";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 545,
    margin: theme.spacing(1)
  },
  media: {
    height: 140
  },
  location: {
    marginBottom: 12
  },
  days: {
    marginBottom: 6
  },
  dates: {
    // marginTop: 6
  },
  button: {
    color: theme.palette.secondary.dark
  }
}));

const ListingTile = ({ listing, authUser, showActions = true }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea component={Link} to={`/listings/${listing.uid}`}>
        <CardMedia
          component="img"
          className={classes.media}
          image={
            listing.photos[0]
              ? listing.photos[0]
              : "https://firebasestorage.googleapis.com/v0/b/lettuscamp.appspot.com/o/images%2Fcamping-tent.png?alt=media&token=79a1a4b6-f3e2-4ba9-9b31-d2767d6f88be"
          }
          title={listing.title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {listing.title}
          </Typography>
          <LocationChip location={listing.location} />
        </CardContent>
      </CardActionArea>

      {showActions && (
        <CardActions>
          <Button
            size="small"
            // color="secondary"
            className={classes.button}
            component={Link}
            to={`/listings/${listing.uid}`}
          >
            Learn More
          </Button>
          <ShareListingButton listingUid={listing.uid} title={listing.title} />
          <FavoritesIconButton listingUid={listing.uid} />
        </CardActions>
      )}
    </Card>
  );
};

export default ListingTile;
