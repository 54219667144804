import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";

// Redux
import { connect } from "react-redux";
import { AUTH_USER_LOADING, STOP_AUTH_USER_LOADING } from "../redux/types";

// Components
import { withFirebase } from "../components/Firebase";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import styles from "../util/styles";

import redirect from "../util/redirect";

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  error: null
};

class SignUpFormBase extends Component {
  state = { ...INITIAL_STATE };

  onSubmit = event => {
    event.preventDefault();

    const { username, email, passwordOne } = this.state;

    this.props.onAuthUserLoading();

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser =>
        // Create a user in the db
        this.props.firebase
          .users()
          .doc(authUser.user.uid)
          .set(
            {
              username,
              email,
              providerId: "email"
            },
            { merge: true }
          )
      )
      .then(() => this.props.firebase.doSendEmailVerification())
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.onStopAuthUserLoading();
        redirect(this.props.history);
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { username, email, passwordOne, error } = this.state;
    const title = this.props.title ? this.props.title : "Sign Up";

    const isInvalid = passwordOne === "" || email === "" || username === "";

    return (
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          {/* <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar> */}
          <Typography component="h2" variant="h5" color="textSecondary">
            {title}
          </Typography>

          <Typography component="h2" variant="h5">
            {error && <p>{error.message}</p>}
          </Typography>

          <form className={classes.form} onSubmit={this.onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="name"
                  name="username"
                  value={username}
                  variant="outlined"
                  required
                  fullWidth
                  id="username"
                  label="Full Name"
                  autoFocus
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="email"
                  name="email"
                  value={email}
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="new-password"
                  name="passwordOne"
                  value={passwordOne}
                  variant="outlined"
                  required
                  fullWidth
                  type="password"
                  id="passwordOne"
                  label="Password"
                  onChange={this.onChange}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Grid item>
                <Button
                  disabled={isInvalid}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Sign Up
                </Button>
              </Grid>
            </Grid>
            {/* <Grid container justify="center">
              <Grid item>
                <Link to="/signin" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid> */}
          </form>
        </div>
      </Container>
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to="/signup">Sign Up</Link>
  </p>
);

const mapDispatchToProps = dispatch => ({
  onAuthUserLoading: () => dispatch({ type: AUTH_USER_LOADING }),
  onStopAuthUserLoading: () => dispatch({ type: STOP_AUTH_USER_LOADING })
});

const SignUpForm = compose(
  withStyles(styles),
  withRouter,
  withFirebase,
  connect(null, mapDispatchToProps)
)(SignUpFormBase);

export default SignUpForm;

export { SignUpLink };
