import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import { mdiFacebookBox } from "@mdi/js";
import SvgIcon from "@material-ui/core/SvgIcon";

// Redux
import { connect } from "react-redux";
import { AUTH_USER_LOADING, STOP_AUTH_USER_LOADING } from "../redux/types";

// Components
import { withFirebase } from "../components/Firebase";
import { ForgotPasswordLink } from "./ForgotPassword";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import styles from "../util/styles";

import redirect from "../util/redirect";

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  onSubmit = event => {
    event.preventDefault();

    this.props.onAuthUserLoading();

    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE, error: null });
        this.props.onStopAuthUserLoading();
        redirect(this.props.history);
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;
    const { classes } = this.props;

    const isInvalid = password === "" || email === "";

    return (
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Typography component="h2" variant="h5" color="textSecondary">
            Log In
          </Typography>
          <Typography component="h2" variant="h5">
            {error && <p>{error.message}</p>}
          </Typography>

          <form className={classes.form} onSubmit={this.onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="email"
                  name="email"
                  value={email}
                  variant="outlined"
                  autoFocus
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="current-password"
                  name="password"
                  value={password}
                  variant="outlined"
                  required
                  fullWidth
                  type="password"
                  id="password"
                  label="Password"
                  onChange={this.onChange}
                />
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Grid item>
                <Button
                  disabled={isInvalid}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Log In
                </Button>
              </Grid>
            </Grid>
          </form>
          <ForgotPasswordLink />
        </div>
      </Container>
    );
  }
}

class SignInGoogleBase extends Component {
  state = { error: null };

  // componentDidMount() {
  //   console.log("mounting");

  //   this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
  //     this.props.firebase.getRedirectResult().then(socialAuthUser => {
  //       console.log("Google onAuthStateChanged");

  //       if (socialAuthUser.user) {
  //         // Create a user in the db
  //         return this.props.firebase
  //           .users()
  //           .doc(socialAuthUser.user.uid)
  //           .set(
  //             {
  //               username: socialAuthUser.user.displayName,
  //               email: socialAuthUser.user.email,
  //               photoUrl: socialAuthUser.user.photoURL
  //             },
  //             { merge: true }
  //           )
  //           .then(() => {
  //             this.setState({ error: null });
  //             redirect(this.props.history);
  //           })
  //           .catch(error => {
  //             this.setState({ error });
  //           });
  //       }
  //     });
  //   });
  // }

  // componentWillUnmount() {
  //   this.listener();
  // }

  onSubmit = event => {
    event.preventDefault();
    this.props.onAuthUserLoading();
    // this.props.firebase.googleSignInWithRedirect();
    this.props.firebase
      .doSignInWithGooglePopup()
      .then(socialAuthUser => {
        console.log("doSignInWithGooglePopup socialAuthUser");
        console.log(socialAuthUser);

        return this.props.firebase
          .users()
          .doc(socialAuthUser.user.uid)
          .set(
            {
              username: socialAuthUser.user.providerData[0].displayName,
              email: socialAuthUser.user.providerData[0].email,
              photoUrl: socialAuthUser.user.providerData[0].photoURL,
              providerId: socialAuthUser.user.providerData[0].providerId
            },
            { merge: true }
          );
      })
      .then(() => {
        this.setState({ error: null });
        this.props.onStopAuthUserLoading();
        redirect(this.props.history);
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          className={this.props.classes.googleButton}
          // startIcon={<SaveIcon />}
          // startIcon={
          //   <SvgIcon>
          //     <path d={mdiGoogle} />
          //   </SvgIcon>
          // }
          startIcon={
            <img
              width="20px"
              height="20px"
              alt="Google sign-in"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
            />
          }
        >
          Continue with Google
        </Button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInFacebookBase extends Component {
  state = { error: null };

  // componentDidMount() {
  //   this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
  //     this.props.firebase.getRedirectResult().then(socialAuthUser => {
  //       console.log("facebook onAuthStateChanged");

  //       if (socialAuthUser.user) {
  //         // Create a user in the db

  //         // TODO: add photo url
  //         console.log(socialAuthUser.additionalUserInfo);

  //         return this.props.firebase
  //           .users()
  //           .doc(socialAuthUser.user.uid)
  //           .set(
  //             {
  //               username: socialAuthUser.additionalUserInfo.profile.name,
  //               email: socialAuthUser.additionalUserInfo.profile.email
  //               // photoUrl: socialAuthUser.additionalUserInfo.profile.photoURL
  //             },
  //             { merge: true }
  //           )
  //           .then(() => {
  //             this.setState({ error: null });
  //             redirect(this.props.history);
  //           })
  //           .catch(error => {
  //             this.setState({ error });
  //           });
  //       }
  //     });
  //   });
  // }

  // componentWillUnmount() {
  //   this.listener();
  // }

  onSubmit = event => {
    event.preventDefault();
    this.props.onAuthUserLoading();
    // this.props.firebase.facebookSignInWithRedirect();
    this.props.firebase
      .doSignInWithFacebookPopup()
      .then(socialAuthUser =>
        this.props.firebase
          .users()
          .doc(socialAuthUser.user.uid)
          .set(
            {
              username: socialAuthUser.additionalUserInfo.profile.name,
              email: socialAuthUser.additionalUserInfo.profile.email,
              photoUrl:
                socialAuthUser.additionalUserInfo.profile.picture.data.url,
              providerId: socialAuthUser.additionalUserInfo.providerId
            },
            { merge: true }
          )
      )
      .then(() => {
        this.setState({ error: null });
        this.props.onStopAuthUserLoading();
        redirect(this.props.history);
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          className={this.props.classes.facebookButton}
          // startIcon={<SaveIcon />}
          // startIcon={<FacebookIcon size={30} />}
          startIcon={
            <SvgIcon>
              <path d={mdiFacebookBox} />
            </SvgIcon>
          }
        >
          Continue with Facebook
        </Button>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onAuthUserLoading: () => dispatch({ type: AUTH_USER_LOADING }),
  onStopAuthUserLoading: () => dispatch({ type: STOP_AUTH_USER_LOADING })
});

const SignInForm = compose(
  withStyles(styles),
  withRouter,
  withFirebase,
  connect(null, mapDispatchToProps)
)(SignInFormBase);

const SignInGoogle = compose(
  withStyles(styles),
  withRouter,
  withFirebase,
  connect(null, mapDispatchToProps)
)(SignInGoogleBase);

const SignInFacebook = compose(
  withStyles(styles),
  withRouter,
  withFirebase,
  connect(null, mapDispatchToProps)
)(SignInFacebookBase);

export { SignInForm, SignInGoogle, SignInFacebook };
