export default name => {
  const names = name && name.split(" ");

  if (names && names.length && names.length > 1) {
    return `${names[0]} ${names[names.length - 1].slice(0, 1)}`; // First name, last initial
  } else if (names && names.length === 1) {
    return names[0];
  } else {
    return `unknown`;
  }
};
