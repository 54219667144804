import {
  LISTING_FILE_SET,
  LISTING_URL_SET,
  LISTING_IMAGES_RESET,
  LISTING_START_DATE_SET,
  LISTING_END_DATE_SET,
  LISTING_TITLE_SET,
  LISTING_TYPE_SET,
  LISTING_LOCATION_SET,
  LISTING_PLACE_SET,
  LISTING_DESCRIPTION_SET,
  LISTING_PRICE_SET,
  LISTING_RESET,
  LISTING_SET,
  LISTING_CLOSE_SET,
  LISTING_OPEN_SET
} from "../types";

// This only stores the dates and files for upload, not the rest of the new listing details.
const INITIAL_STATE = {
  startDate: null,
  endDate: null,
  datesValid: true,
  files: [],
  imagePreviewUrls: [],
  title: "",
  listingType: "",
  location: "",
  place: {},
  description: "",
  price: "",
  photos: [],
  userId: "",
  closed: false
};

const isValid = (startDate, endDate) =>
  (startDate <= endDate && startDate !== null && endDate !== null) ||
  (startDate === null && endDate === null);

const newListingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LISTING_FILE_SET: {
      const { files } = state;

      return {
        ...state,
        files: [action.file, ...files.slice()]
      };
    }

    case LISTING_URL_SET: {
      const { imagePreviewUrls } = state;

      return {
        ...state,
        imagePreviewUrls: [action.imagePreviewUrl, ...imagePreviewUrls.slice()]
      };
    }

    case LISTING_IMAGES_RESET: {
      return {
        ...state,
        files: [],
        imagePreviewUrls: []
      };
    }

    case LISTING_START_DATE_SET: {
      return {
        ...state,
        startDate: action.startDate,
        datesValid: isValid(action.startDate, state.endDate)
      };
    }

    case LISTING_END_DATE_SET: {
      return {
        ...state,
        endDate: action.endDate,
        datesValid: isValid(state.startDate, action.endDate)
      };
    }

    case LISTING_SET: {
      if (!action.listing) return { ...INITIAL_STATE };

      const {
        startDate,
        endDate,
        photos,
        title,
        listingType,
        description,
        location,
        place,
        price,
        userId
      } = action.listing;
      return action.listing
        ? {
            photos,
            startDate:
              startDate instanceof Date || startDate === null
                ? startDate
                : startDate
                ? startDate.toDate()
                : null,
            endDate:
              endDate instanceof Date || endDate === null
                ? endDate
                : startDate
                ? endDate.toDate()
                : null,
            datesValid: true,
            files: [],
            imagePreviewUrls: [],
            title,
            listingType: listingType ? listingType : "",
            location,
            place: place ? place : {},
            description,
            price,
            userId,
            closed:
              action.listing.closed !== undefined
                ? action.listing.closed
                : false
          }
        : {
            ...INITIAL_STATE
          };
    }

    case LISTING_TITLE_SET: {
      return {
        ...state,
        title: action.title
      };
    }
    case LISTING_TYPE_SET: {
      return {
        ...state,
        listingType: action.listingType ? action.listingType : ""
      };
    }
    case LISTING_LOCATION_SET: {
      return {
        ...state,
        location: action.location
      };
    }
    case LISTING_PLACE_SET: {
      return {
        ...state,
        place: action.place
      };
    }
    case LISTING_DESCRIPTION_SET: {
      return {
        ...state,
        description: action.description
      };
    }
    case LISTING_PRICE_SET: {
      return {
        ...state,
        price: action.price
      };
    }
    case LISTING_CLOSE_SET: {
      return {
        ...state,
        closed: true
      };
    }
    case LISTING_OPEN_SET: {
      return {
        ...state,
        closed: false
      };
    }
    case LISTING_RESET: {
      return {
        ...INITIAL_STATE
      };
    }
    default:
      return state;
  }
};

export default newListingReducer;
