import {
  AUTH_USER_SET,
  AUTH_USER_LOADING,
  STOP_AUTH_USER_LOADING
} from "../types";

const INITIAL_STATE = {
  authUser: null,
  loading: false
};

const sessionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_USER_SET: {
      return {
        ...state,
        authUser: action.authUser
      };
    }
    case AUTH_USER_LOADING:
      return {
        ...state,
        loading: true
      };
    case STOP_AUTH_USER_LOADING:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};

export default sessionReducer;
