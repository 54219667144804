import React from "react";

// Material UI
import Grid from "@material-ui/core/Grid";

import ListingTile from "./ListingTile";

const ListingList = ({
  authUser,
  listings
  // onEditListing,
  // onRemoveListing
}) => (
  <Grid container direction="row" justify="center">
    {listings.map(
      listing =>
        listing && ( // May be null if listing has been deleted
          <Grid item xs={12} sm={6} key={listing.uid}>
            <ListingTile
              authUser={authUser}
              key={listing.uid}
              listing={listing}
              // onEditListing={onEditListing}
              // onRemoveListing={onRemoveListing}
            />
          </Grid>
        )
    )}
  </Grid>
);

export default ListingList;
