import React, { Component } from "react";
import { compose } from "recompose";
import { Link, withRouter } from "react-router-dom";

import fmtName from "../../util/fmtName";

// Redux
import { connect } from "react-redux";
import { CHAT_MESSAGES_SET, CHAT_LOADING, CHAT_SET } from "../../redux/types";

// Firebase
import { withFirebase } from "../Firebase";

// Components
import MessageList from "./MessageList";

// Material UI
// import styles from "../../util/styles";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import SendIcon from "@material-ui/icons/Send";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  titlePaper: {
    padding: theme.spacing(3, 2)
  },
  textContainer: {
    position: "fixed",
    left: theme.spacing(2),
    bottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    opacity: 0.9
  },
  titleContainer: {
    position: "fixed",
    left: theme.spacing(2),
    zIndex: 1000,
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "transparent",
    opacity: 0.9
  },
  button: {
    margin: theme.spacing(1)
  }
});

class Chat extends Component {
  state = {
    textMessage: "",
    notifications: []
  };

  componentWillMount() {
    this.props.onSetChatLoading();
    const { chatId } = this.props.match.params;

    this.props.firebase
      .chat(chatId)
      .get()
      .then(doc => {
        if (!doc.exists) this.props.onSetChat(null);

        this.props.onSetChat({ chatId, ...doc.data() });
      });

    this.onListenForMessages(chatId);
    this.onListenForNotifications(chatId);
  }

  // Grabs all unread notifications for this chat and marks as read.
  onListenForNotifications = chatId => {
    const { authUser } = this.props;

    this.unsubscribeNotifications = this.props.firebase
      .getUnreadNotificationsForChat(authUser.uid, chatId)
      .onSnapshot(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.props.firebase.markNotificationRead(doc.id);
        });
      });
  };

  // Grabs all messages for the given chatId
  onListenForMessages = chatId => {
    if (!this.unsubscribe) {
      // Get all the messages.
      this.unsubscribe = this.props.firebase
        .chat(chatId)
        .collection("messages")
        .orderBy("createdAt", "asc")
        .onSnapshot(querySnapshot => {
          const messages = [];
          querySnapshot.forEach(doc => {
            messages.push({ ...doc.data(), uid: doc.id });
          });

          this.props.onSetMessages(messages);
          // Grab GCM device token.
          this.props.firebase.saveMessagingDeviceToken();
        });
    }
  };

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
    this.unsubscribeNotifications && this.unsubscribeNotifications();
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClick = () => {
    const {
      chat: {
        chatId,
        sellerId,
        senderId,
        title,
        sellerUsername,
        sellerPhotoUrl,
        senderUsername,
        senderPhotoUrl
      },
      authUser
    } = this.props;
    const { textMessage } = this.state;

    if (!textMessage) return;

    const message = {
      createdAt: this.props.firebase.serverTimestamp(),
      userId: authUser.uid,
      recipientId: authUser.uid === senderId ? sellerId : senderId,
      text: textMessage,
      title,
      senderName: authUser.uid === senderId ? senderUsername : sellerUsername,
      senderPhotoUrl:
        authUser.uid === senderId ? senderPhotoUrl : sellerPhotoUrl
    };

    this.props.firebase
      .chat(chatId)
      .collection("messages")
      .add(message)
      .then(docRef => {
        this.setState({ textMessage: "" });
      })
      .catch(error => {
        console.log(error);
      });

    this.props.firebase
      .chat(chatId)
      .set(
        { updatedAt: this.props.firebase.serverTimestamp() },
        { merge: true }
      );
  };

  render() {
    const { loading, chat, authUser, messages, classes } = this.props;
    const { textMessage } = this.state;

    return (
      <React.Fragment>
        {loading && <LinearProgress color="secondary" />}

        {!loading && chat && (
          <React.Fragment>
            <Grid
              container
              className={classes.titleContainer}
              direction="row"
              justify="flex-start"
              alignItems="center"
              component={Link}
              to={`/listings/${chat.listingId}`}
            >
              <Grid item xs={12}>
                <Paper className={classes.titlePaper}>
                  <Typography variant="h5" component="h3">
                    {authUser.uid === chat.sellerId
                      ? fmtName(chat.senderUsername)
                      : fmtName(chat.sellerUsername)}
                  </Typography>
                  <Typography component="span" variant="body2">
                    {chat.title} - {chat.location}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            <MessageList />

            <Grid
              container
              className={classes.textContainer}
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={8}>
                <TextField
                  // autoFocus
                  id="text-message-input"
                  name="textMessage"
                  placeholder="Text message"
                  multiline
                  rowsMax="4"
                  fullWidth
                  variant="outlined"
                  value={textMessage}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  disabled={!chat || !textMessage}
                  variant="contained"
                  color="primary"
                  startIcon={<SendIcon />}
                  className={classes.button}
                  onClick={this.handleClick}
                >
                  send
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  loading: state.chatState.loading,
  chat: state.chatState.chat,
  messages: state.chatState.messages
});

const mapDispatchToProps = dispatch => ({
  onSetMessages: messages => dispatch({ type: CHAT_MESSAGES_SET, messages }),
  onSetChatLoading: () => dispatch({ type: CHAT_LOADING }),
  onSetChat: chat => dispatch({ type: CHAT_SET, chat })
});

export default compose(
  withStyles(styles),
  withFirebase,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Chat);
