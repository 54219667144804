import React, { Component } from "react";
import { compose } from "recompose";

// Components
import Chat from "../components/Chat";
import { withAuthorization } from "../components/Session";

export class ChatPage extends Component {
  render() {
    return <Chat />;
  }
}

const condition = authUser => !!authUser;

export default compose(withAuthorization(condition))(ChatPage);
