import React, { Component, Fragment } from "react";
import { compose } from "recompose";
import Script from "react-load-script";

// Redux
import { connect } from "react-redux";
import { LISTING_LOCATION_SET, LISTING_PLACE_SET } from "../../redux/types";

// Material UI
import TextField from "@material-ui/core/TextField";

// Google Places API
const placesApiKey = process.env.REACT_APP_PLACES_API_KEY;
const placesScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${placesApiKey}&libraries=places`;

export class Autocomplete extends Component {
  handleScriptLoad = () => {
    // Declare Options For Autocomplete
    // componentRestrictions restricted to 'us' for now.
    // You can provide a single one, or an array of up to five country code strings.
    // See this link for details: https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#ComponentRestrictions

    // const options = { types: ["(cities)"] };
    const options = {
      types: ["establishment"],
      componentRestrictions: { country: "us" }
    };

    // Initialize Google Autocomplete
    // Required to disable any eslint 'google not defined' errors:
    /*global google*/
    this.locationAutocomplete = new google.maps.places.Autocomplete(
      document.getElementById("locationAutocomplete"),
      options
    );

    // Avoid paying for data that you don't need by restricting the
    // set of place fields that are returned to just the address
    // components and formatted address
    //
    // Other fields of interest (full listing: https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult)
    //  reviews
    //  rating
    //  photos
    //  opening_hours
    //  website
    //  url (official google page)
    //  name (raw text)
    //  place_id
    //  address_components
    //  formatted_address
    this.locationAutocomplete.setFields([
      "rating",
      "url",
      "place_id",
      "geometry"
    ]);

    // Fire Event when a suggested name is selected
    this.locationAutocomplete.addListener(
      "place_changed",
      this.handlePlaceSelect
    );
  };

  handlePlaceSelect = () => {
    const locationValue = document.getElementById("locationAutocomplete").value;

    // Set location from autocomplete
    const location = locationValue.split(",")[0];
    this.props.onSetLocation(location);

    // Pull additional place information
    const addressObject = this.locationAutocomplete.getPlace();
    const { rating, url, place_id: placeId, geometry } = addressObject;

    // Check if valid
    if (geometry) {
      const lat = geometry.location.lat();
      const lng = geometry.location.lng();
      const place = {
        locationValue,
        rating,
        url,
        placeId,
        geometry: { lat, lng }
      };

      this.props.onSetPlace(place);
    }
  };

  onLocationChange = event => {
    this.props.onSetLocation(event.target.value);
  };

  render() {
    const { location, loading } = this.props;

    return (
      <Fragment>
        <Script url={placesScriptUrl} onLoad={this.handleScriptLoad} />
        <TextField
          autoFocus
          disabled={loading}
          name="location"
          value={location}
          variant="standard"
          required
          fullWidth
          id="locationAutocomplete"
          label="Location (required)"
          placeholder="Area, location, or address"
          onChange={this.onLocationChange}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  location: state.listingState.location,
  place: state.listingState.place
});

const mapDispatchToProps = dispatch => ({
  onSetLocation: location => dispatch({ type: LISTING_LOCATION_SET, location }),
  onSetPlace: place => dispatch({ type: LISTING_PLACE_SET, place })
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Autocomplete
);
