import React, { Component, Fragment } from "react";
import { compose } from "recompose";

// Redux
import { connect } from "react-redux";
import {
  LISTINGS_SET,
  LISTINGS_NEXT_SET,
  LISTINGS_LIMIT_SET
} from "../../redux/types";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

// Firebase
import { withFirebase } from "../Firebase";

// Components
import ListingList from "./ListingList";

const styles = theme => ({
  button: {
    margin: theme.spacing(3, 0, 2)
  }
});

class Listings extends Component {
  state = {
    // text: "",
    loading: false,
    lastDoc: null
  };

  handleScroll = () => {
    if (!this.state.loading) {
      const windowHeight =
        "innerHeight" in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight =
        Math.max(
          body.scrollHeight,
          body.offsetHeight,
          html.clientHeight,
          html.scrollHeight,
          html.offsetHeight
        ) * 0.8;
      const windowBottom = Math.round(windowHeight + window.pageYOffset);

      if (windowBottom >= docHeight) {
        this.setState({ loading: true });
        this.getNextListings();
      }
    }
  };

  componentDidMount() {
    if (!this.props.listings.length) {
      this.setState({ loading: true });
    }
    // this.onListenForListings();
    this.getListings();

    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(props) {
    if (props.limit !== this.props.limit) {
      // this.unsubscribe && this.unsubscribe();
      // this.onListenForListings();
    }
  }

  componentWillUnmount() {
    // this.unsubscribe();

    window.removeEventListener("scroll", this.handleScroll);
  }

  getListings = () => {
    this.props.firebase
      .listings()
      .where("closed", "==", false)
      .orderBy("createdAt", "desc")
      .limit(this.props.limit)
      .get()
      .then(querySnapshot => {
        const listings = [];

        querySnapshot.forEach(doc => {
          listings.push({ ...doc.data(), uid: doc.id });
        });

        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        this.props.onSetListings(listings);
        this.setState({
          loading: false,
          lastDoc
        });
      });
  };

  getNextListings = () => {
    const { lastDoc } = this.state;

    if (lastDoc)
      this.props.firebase
        .listings()
        .where("closed", "==", false)
        .orderBy("createdAt", "desc")
        .limit(this.props.limit)
        .startAfter(lastDoc)
        .get()
        .then(querySnapshot => {
          const listings = [];

          querySnapshot.forEach(doc => {
            listings.push({ ...doc.data(), uid: doc.id });
          });

          const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

          this.props.onSetNextListings(listings);
          this.setState({
            loading: false,
            lastDoc
          });
        });
    else this.setState({ loading: false });
  };

  // onListenForListings = () => {
  //   this.unsubscribe = this.props.firebase.onSnapshot(snapshot => {
  //     if (snapshot.size) {
  //       let listings = [];
  //       snapshot.forEach(doc => listings.push({ ...doc.data(), uid: doc.id }));
  //       this.props.onSetListings(listings);
  //       this.setState({
  //         loading: false
  //       });
  //     } else {
  //       this.props.onSetListings(null);
  //       this.setState({ loading: false });
  //     }
  //   });
  // };

  // onChangeText = event => {
  //   this.setState({ text: event.target.value });
  // };

  // onCreateListing = (event, authUser) => {
  //   event.preventDefault();

  //   this.props.firebase.listings().add({
  //     text: this.state.text,
  //     userId: authUser.uid,
  //     createdAt: this.props.firebase.serverTimestamp()
  //   });

  //   this.setState({ text: "" });
  // };

  // onEditListing = (listing, text) => {
  //   const { uid, ...listingSnapshot } = listing;

  //   this.props.firebase.listing(uid).set(
  //     {
  //       ...listingSnapshot,
  //       text,
  //       editedAt: this.props.firebase.serverTimestamp()
  //     },
  //     { merge: true }
  //   );
  // };

  // onRemoveListing = uid => {
  //   this.props.firebase.listing(uid).delete();
  // };

  // onNextPage = () => {
  //   this.props.onSetListingsLimit(this.props.limit + 5);
  // };

  render() {
    const { listings, classes } = this.props;
    const { loading } = this.state;

    return (
      <Fragment>
        {listings && (
          <ListingList authUser={this.props.authUser} listings={listings} />
        )}
        {!listings && <div>There are no listings...</div>}

        {loading && <LinearProgress color="secondary" />}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  listings: Object.keys(state.listingsState.listings || {}).map(key => ({
    ...state.listingsState.listings[key]
  })),
  limit: state.listingsState.limit
});

const mapDispatchToProps = dispatch => ({
  onSetListings: listings => dispatch({ type: LISTINGS_SET, listings }),
  onSetNextListings: listings =>
    dispatch({ type: LISTINGS_NEXT_SET, listings }),
  onSetListingsLimit: limit => dispatch({ type: LISTINGS_LIMIT_SET, limit })
});

export default compose(
  withStyles(styles),
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(Listings);
