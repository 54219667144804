import React from "react";
import Avatar from "@material-ui/core/Avatar";

import fmtName from "../../util/fmtName";

export default (props) =>
  props.photoUrl ? (
    <Avatar alt={fmtName(props.username)} src={props.photoUrl} />
  ) : (
    <Avatar alt={fmtName(props.username)}>
      {props.username ? fmtName(props.username.slice(0, 1)) : ""}
    </Avatar>
  );
