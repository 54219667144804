import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

// Redux
import { connect } from "react-redux";
import { STOP_AUTH_USER_LOADING } from "../redux/types";

// Firebase
import { withFirebase } from "../components/Firebase";

// Components
import { SignInForm, SignInGoogle, SignInFacebook } from "../components/SignIn";
import SignUpForm from "../components/SignUp";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CameraAlt from "@material-ui/icons/CameraAlt";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Message from "@material-ui/icons/Message";
import styles from "../util/styles";
import Link from "@material-ui/core/Link";

const Copyright = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {"Copyright © "}
    <Link
      href="https://lettuscamp.com/"
      target="_blank"
      rel="noopener"
      color="inherit"
    >
      LettUsCamp.com
    </Link>{" "}
    {new Date().getFullYear()}
    {"."}
  </Typography>
);

class SignInPage extends Component {
  state = {
    emailSignUp: false,
    emailSignIn: false,
    error: null,
  };

  // componentDidMount() {
  //   this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
  //     if (authUser) {
  //       this.props.firebase.getRedirectResult().then(async socialAuthUser => {
  //         if (socialAuthUser.user) {
  //           // Google or Facebook
  //           const userInfo = socialAuthUser.additionalUserInfo
  //             ? {
  //                 username: socialAuthUser.additionalUserInfo.profile.name,
  //                 email: socialAuthUser.additionalUserInfo.profile.email
  //                 // TODO: test
  //                 // photoUrl: socialAuthUser.additionalUserInfo.profile.photoURL
  //               }
  //             : {
  //                 username: socialAuthUser.user.displayName,
  //                 email: socialAuthUser.user.email,
  //                 photoUrl: socialAuthUser.user.photoURL
  //               };

  //           await this.props.firebase
  //             .users()
  //             .doc(socialAuthUser.user.uid)
  //             .set({ ...userInfo }, { merge: true })
  //             .then(() => {
  //               this.setState({ error: null });
  //               this.props.onStopAuthUserLoading();
  //               redirect(this.props.history);
  //             })
  //             .catch(error => {
  //               this.setState({ error });
  //             });
  //         } else {
  //           // Email login
  //           this.props.onStopAuthUserLoading();
  //           redirect(this.props.history);
  //         }
  //       });
  //     }
  //   });
  // }

  // componentWillUnmount() {
  //   this.listener();
  // }

  handleEmailSignUp = () => {
    this.setState({ emailSignUp: true, emailSignIn: false });
  };

  handleEmailSignIn = () => {
    this.setState({ emailSignUp: false, emailSignIn: true });
  };

  render() {
    const { emailSignUp, emailSignIn } = this.state;
    const { classes, loading } = this.props;

    return (
      <Fragment>
        <Container component="main" maxWidth="xs">
          <Grid container justify="center" spacing={2}>
            {loading && (
              <Grid item xs={12}>
                <Typography
                  className={classes.paper}
                  variant="h5"
                  component="h3"
                  color="textSecondary"
                >
                  Loading...
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography
                className={classes.paper}
                align="center"
                variant="h5"
                component="h3"
                color="textSecondary"
              >
                Sign in to unlock these features
              </Typography>
            </Grid>
            <Grid item xs={3} align="center">
              <CameraAlt />
              <Typography>Share your campsite</Typography>
            </Grid>
            <Grid item xs={3} align="center">
              <Message />
              <Typography>Message the Host</Typography>
            </Grid>
            <Grid item xs={3} align="center">
              <FavoriteIcon />
              <Typography>Save campsites</Typography>
            </Grid>
          </Grid>

          <Typography
            className={classes.paper}
            variant="h5"
            component="h3"
            color="textSecondary"
          >
            Quickly Connect With
          </Typography>
          <SignInGoogle />
          <SignInFacebook />

          <Grid container justify="center" spacing={2}>
            <Grid item xs={12}>
              <Typography
                className={classes.paper}
                variant="h5"
                component="h3"
                color="textSecondary"
              >
                Or Use Your Email
              </Typography>
            </Grid>
            <Grid item xs={6} align="right">
              <Button color="default" onClick={this.handleEmailSignUp}>
                Sign Up
              </Button>
            </Grid>
            <Grid item xs={6} align="left">
              <Button color="default" onClick={this.handleEmailSignIn}>
                Log In
              </Button>
            </Grid>
          </Grid>
        </Container>

        {emailSignIn && <SignInForm />}

        {emailSignUp && <SignUpForm />}

        <Box mt={5}>
          <Copyright />
        </Box>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.sessionState.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onStopAuthUserLoading: () => dispatch({ type: STOP_AUTH_USER_LOADING }),
});

export default compose(
  withStyles(styles),
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(SignInPage);
