import {
  CHAT_SET,
  CHAT_LOADING,
  CHAT_MESSAGES_SET,
  CHAT_CLEAR,
  CHATS_SET,
  CHATS_CLEAR,
  CHATS_LOADING
} from "../types";

const INITIAL_STATE = {
  chat: null,
  messages: [],
  loading: false,
  loadingChats: false,
  sellerChats: [],
  senderChats: []
};

const chatReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHAT_LOADING: {
      return {
        ...state,
        chat: null, // Clear the old chat and messages when we are loading.
        messages: [],
        loading: true
      };
    }
    case CHAT_SET: {
      return {
        ...state,
        chat: action.chat,
        loading: false
      };
    }
    case CHAT_MESSAGES_SET: {
      return {
        ...state,
        messages: action.messages
      };
    }
    case CHAT_CLEAR: {
      return { ...state, loading: false, chat: null, messages: [] };
    }
    case CHATS_LOADING: {
      return {
        ...state,
        loadingChats: true
      };
    }
    case CHATS_CLEAR: {
      return {
        ...state,
        loadingChats: false,
        sellerChats: [],
        senderChats: []
      };
    }
    case CHATS_SET: {
      // Note: any timestamps in sellerChats and senderChats will be regular objects
      // instead of Timestamps. For example use (updateAt._seconds * 1000) when
      // converting dates.
      return {
        ...state,
        loadingChats: false,
        sellerChats: action.sellerChats,
        senderChats: action.senderChats
      };
    }
    default:
      return state;
  }
};

export default chatReducer;
