export default [
  {
    value: "campsite",
    label: "Campsite"
  },
  {
    value: "rv site",
    label: "RV Site"
  },
  // {
  //   value: "extra tent spot",
  //   label: "Extra tent spot"
  // },
  {
    value: "cabin",
    label: "Cabin"
  }
];
