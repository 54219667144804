import React, { Fragment } from "react";
import { compose } from "recompose";

// Redux
import { connect } from "react-redux";

// Components
import ListingList from "../Listings/ListingList";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

// Firebase
import { withFirebase } from "../Firebase";

const styles = theme => ({});

class Favorites extends React.Component {
  state = {
    loading: false,
    listings: null,
    error: null
  };

  componentDidMount() {
    this.setState({ loading: true });
  }

  componentDidUpdate() {
    if (this.props.userState && this.state.loading) this.loadFavorites();
  }

  loadFavorites = async () => {
    const { favorites } = this.props.userState;

    // Load each favorite listing individually.
    const listings = favorites
      ? await Promise.all(
          favorites.map(favorite => {
            return this.props.firebase
              .listings()
              .doc(favorite)
              .get()
              .then(doc => {
                if (doc.exists) return { ...doc.data(), uid: doc.id };
                else return null;
              });
          })
        ).catch(error => this.setState({ error }))
      : [];

    this.setState({ listings, loading: false });
  };

  render() {
    const { loading, listings, error } = this.state;
    const { classes } = this.props;

    return (
      <Fragment>
        {listings && (
          <ListingList authUser={this.props.authUser} listings={listings} />
        )}
        {error && <div>{error.message}</div>}
        {loading && <LinearProgress color="secondary" />}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  userState: state.userState.user
});

export default compose(
  withStyles(styles),
  withFirebase,
  connect(mapStateToProps)
)(Favorites);
