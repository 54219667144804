import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";

// Material-UI
import { ThemeProvider } from "@material-ui/core/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import themeFile from "./util/theme";

// Pages
import Home from "./pages/Home";
import SignInPage from "./pages/SignIn";
import ForgotPasswordPage from "./pages/ForgotPassword";
import AccountPage from "./pages/Account";
import AdminPage from "./pages/Admin";
import ListingDetail from "./pages/ListingDetail";
import NewListing from "./pages/NewListing";
import FavoritesPage from "./pages/Favorites";
import MyListingsPage from "./pages/MyListings";
import EditListing from "./pages/EditListing";
import MessagesPage from "./pages/Messages";
import ChatPage from "./pages/Chat";
import Notifications from "./pages/Notifications";

// Components
import Navbar from "./components/Navbar";

// Firebase and Auth context
import { withAuthentication } from "./components/Session";

const theme = createMuiTheme(themeFile);

const App = () => (
  <ThemeProvider theme={theme}>
    <Router>
      <div className="container">
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/signin" component={SignInPage} />
          <Route path="/forgotpassword" component={ForgotPasswordPage} />
          <Route path="/account" component={AccountPage} />
          <Route path="/chat/:chatId" component={ChatPage} />
          <Route path="/chat" component={MessagesPage} />
          <Route path="/favorites" component={FavoritesPage} />
          {/* <Route path="/admin" component={AdminPage} /> */}
          <Route path="/listings/:uid/edit" component={EditListing} />
          <Route path="/listings/:uid" component={ListingDetail} />
          <Route path="/newlisting" component={NewListing} />
          <Route path="/mylistings" component={MyListingsPage} />
          <Route path="/notifications" component={Notifications} />
        </Switch>
      </div>
    </Router>
  </ThemeProvider>
);

export default withAuthentication(App);
