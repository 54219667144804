import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    render() {
      return condition(this.props.authUser) ? (
        <Component {...this.props} />
      ) : (
        <Redirect
          to={`/signin?redirect=${this.props.location.pathname}${this.props.location.search}`}
        />
      );
    }
  }

  const mapStateToProps = state => ({
    authUser: state.sessionState.authUser
  });

  return compose(
    withRouter,
    withFirebase,
    connect(mapStateToProps)
  )(WithAuthorization);
};

export default withAuthorization;
