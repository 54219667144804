// import purple from "@material-ui/core/colors/purple";
// import green from "@material-ui/core/colors/green";
// import brown from "@material-ui/core/colors/brown";
// import lightBlue from "@material-ui/core/colors/lightBlue";
// import blue from "@material-ui/core/colors/blue";
import pink from "@material-ui/core/colors/pink";
import deepOrange from "@material-ui/core/colors/deepOrange";

export default {
  palette: {
    primary: {
      // main: brown[900]
      // main: lightBlue[500]
      // main: blue[800]
      // main: "#50a3a4"
      light: "#aad67e",
      main: "#79a450",
      dark: "#4a7524"
    },
    secondary: {
      // tealish
      light: "#82d5d5",
      main: "#50a3a4",
      dark: "#167475",

      special: pink[600]

      // light blue
      // light: "#63ccff",
      // main: "#039be5",
      // dark: "#006db3"

      // cyan-700
      // light: "#56c8d8",
      // main: "#0097a7",
      // dark: "#006978"
    },
    error: {
      // main: deepOrange[500]
      main: pink[600]
    }
  },
  status: {
    danger: "orange"
  }
};
