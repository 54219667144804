import React from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import LocationIcon from "@material-ui/icons/LocationOn";

const formatText = text =>
  text ? (text.length <= 30 ? text : text.substring(0, 27) + "...") : "";

const useStyles = makeStyles(theme => ({
  root: {
    // display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: theme.spacing(1, 0, 0, 0)
    // "& > *": {
    //   margin: theme.spacing(0.5)
    // }
  }
}));

const LocationChip = ({ location }) => {
  const classes = useStyles();

  return location ? (
    <Chip
      icon={<LocationIcon />}
      label={formatText(location)}
      color="primary"
      variant="outlined"
      className={classes.root}
    />
  ) : null;
};
export default LocationChip;
