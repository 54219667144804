import { LISTINGS_LIMIT_SET, LISTINGS_NEXT_SET, LISTINGS_SET } from "../types";

const INITIAL_STATE = {
  listings: null,
  limit: 6
};

const applySetListings = (state, action) => ({
  ...state,
  listings: action.listings
});

const applySetListingsLimit = (state, action) => ({
  ...state,
  limit: action.limit
});

const listingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LISTINGS_SET: {
      return applySetListings(state, action);
    }
    case LISTINGS_NEXT_SET: {
      return {
        ...state,
        listings: [...state.listings, ...action.listings]
      };
    }
    case LISTINGS_LIMIT_SET: {
      return applySetListingsLimit(state, action);
    }
    default:
      return state;
  }
};

export default listingReducer;
