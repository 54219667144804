import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { compose } from "recompose";

// Firebase
import { withFirebase } from "../Firebase";

// Redux
import { connect } from "react-redux";
import { CHAT_SET, CHAT_LOADING } from "../../redux/types";

// Material UI
import Fab from "@material-ui/core/Fab";
import MessageIcon from "@material-ui/icons/Message";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  fab: {
    // margin: 0,
    top: "auto",
    left: "auto",
    // left: theme.spacing(2),
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    position: "fixed",
    // "max-width": 500
    margin: theme.spacing(2),
    backgroundColor: theme.palette.secondary.special
  },
  cameraAltIcon: {
    marginRight: theme.spacing(1)
  }
});

class SendMessageFab extends React.Component {
  state = {
    noAuthClicked: false
  };

  handleClick = () => {
    if (!this.props.authUser) {
      this.setState({ noAuthClicked: true });
      return;
    }

    this.props.onSetChatLoading();
    const { listingId, sellerId } = this.props;
    const createChatCallable = this.props.firebase.createChatCallable();
    createChatCallable({ listingId, sellerId })
      .then(result => {
        this.props.onSetChat(result.data);
        const { chatId } = result.data;
        this.props.history.push(`/chat/${chatId}`);
      })
      .catch(error => console.log(error));
  };

  render() {
    const { authUser, classes, loading } = this.props;
    const { noAuthClicked } = this.state;

    return (
      <React.Fragment>
        {!authUser && noAuthClicked && (
          <Redirect
            to={`/signin?redirect=${this.props.location.pathname}${this.props.location.search}`}
          />
        )}
        <Fab
          variant="extended"
          className={classes.fab}
          aria-label="Send Message"
          onClick={this.handleClick}
          disabled={loading}
        >
          <MessageIcon className={classes.cameraAltIcon} />
          Start a Chat with your Host
        </Fab>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  loading: state.chatState.loading
});

const mapDispatchToProps = dispatch => ({
  onSetChatLoading: () => dispatch({ type: CHAT_LOADING }),
  onSetChat: chat => dispatch({ type: CHAT_SET, chat })
});

export default compose(
  withStyles(styles),
  withFirebase,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SendMessageFab);
