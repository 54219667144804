import React from "react";

// Material UI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../util/styles";

import ListingTile from "./ListingTile";

const MyListingList = ({
  authUser,
  listings
  // onEditListing,
  // onRemoveListing
}) => {
  const classes = makeStyles(styles);

  return (
    <React.Fragment>
      <Typography
        className={classes.paper}
        variant="h5"
        component="h3"
        color="textSecondary"
      >
        Manage Your Listings
      </Typography>
      <Grid container direction="row" justify="flex-start">
        {listings.map(
          listing =>
            listing && ( // May be null if listing has been deleted
              <Grid item xs={12} sm={6} key={listing.uid}>
                <ListingTile
                  authUser={authUser}
                  key={listing.uid}
                  listing={listing}
                  showActions={false}
                  // onEditListing={onEditListing}
                  // onRemoveListing={onRemoveListing}
                />
              </Grid>
            )
        )}
      </Grid>
    </React.Fragment>
  );
};

export default MyListingList;
