import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";

// Components
import { withAuthorization } from "../components/Session";
import ChatList from "../components/Chat/ChatList";

class MessagesPage extends React.Component {
  render() {
    return <ChatList />;
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

const condition = authUser => !!authUser;

// export default withAuthorization(condition)(withEmailVerification(AccountPage));
export default compose(
  connect(mapStateToProps),

  withAuthorization(condition)
)(MessagesPage);
