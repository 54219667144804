import React, { Fragment } from "react";

// Material UI
import Typography from "@material-ui/core/Typography";

const ListingPrice = ({ price, className }) =>
  price ? (
    <Fragment>
      <Typography variant="h5" component="h3" className={className}>
        ${price}
      </Typography>
      <Typography variant="body2" component="p" color="textSecondary">
        per night
      </Typography>
    </Fragment>
  ) : null;

export default ListingPrice;
