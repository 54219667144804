import React, { Component } from "react";
import { compose } from "recompose";

// Components
import NotificationsList from "../components/Notifications/NotificationsList";
import { withAuthorization } from "../components/Session";

class NotificationsPage extends Component {
  render() {
    return <NotificationsList />;
  }
}

const condition = authUser => !!authUser;

export default compose(withAuthorization(condition))(NotificationsPage);
