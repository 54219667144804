import React, { Component, Fragment } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";

// Redux
import { connect } from "react-redux";
import { USER_ADD_FAVORITE, USER_REMOVE_FAVORITE } from "../../redux/types";

// Material UI
import IconButton from "@material-ui/core/IconButton";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import { withStyles } from "@material-ui/core/styles";

const iconStyles = theme => ({
  favorite: {
    color: "#f44336"
  }
});

class FavoritesIconButton extends Component {
  state = {
    clicked: false
  };

  onClick = event => {
    event.preventDefault();

    const { authUser, listingUid } = this.props;

    this.setState({ clicked: true });

    if (!authUser) return;

    // TODO: show toast upon add/remove
    if (this.isLiked()) {
      this.props.onRemoveFromFavorites(listingUid);
      this.props.firebase.removeFromFavorites(authUser.uid, listingUid);
    } else {
      this.props.onAddToFavorites(listingUid);
      this.props.firebase.addToFavorites(authUser.uid, listingUid);
    }
  };

  isLiked = () =>
    this.props.userState &&
    this.props.userState.favorites &&
    this.props.userState.favorites.findIndex(
      listingUid => listingUid === this.props.listingUid
    ) > -1;

  render() {
    const { authUser, classes } = this.props;
    const { clicked } = this.state;
    const liked = this.isLiked();

    return (
      <Fragment>
        {!authUser && clicked && (
          <Redirect
            to={`/signin?redirect=${this.props.location.pathname}${this.props.location.search}`}
          />
        )}
        {liked && (
          <IconButton aria-label="added to favorites" onClick={this.onClick}>
            <Favorite
              color="error"
              // className={classes.favorite}
            />
          </IconButton>
        )}
        {!liked && (
          <IconButton aria-label="add to favorites" onClick={this.onClick}>
            <FavoriteBorder />
          </IconButton>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  userState: state.userState.user
});

const mapDispatchToProps = dispatch => ({
  onAddToFavorites: listingUid =>
    dispatch({ type: USER_ADD_FAVORITE, listingUid }),
  onRemoveFromFavorites: listingUid =>
    dispatch({ type: USER_REMOVE_FAVORITE, listingUid })
});

export default compose(
  withStyles(iconStyles),
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(FavoritesIconButton);
