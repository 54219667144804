import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { AUTH_USER_SET, USER_SET } from "../../redux/types";

import { withFirebase } from "../Firebase";

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.props.onSetAuthUser(JSON.parse(localStorage.getItem("authUser")));
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          // User is signed in.
          localStorage.setItem("authUser", JSON.stringify(authUser));
          this.props.onSetAuthUser(authUser);

          // Read the user info and dispatch.
          return this.props.firebase
            .user(authUser.uid)
            .get()
            .then(doc => {
              if (doc.exists) {
                this.props.onSetUser(doc.data());
              } else {
                // doc.data() will be undefined in this case
                this.props.onSetUser(null);
              }
            });
        } else {
          // No user is signed in.
          localStorage.removeItem("authUser");
          this.props.onSetAuthUser(null);
          this.props.onSetUser(null);
        }
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  const mapDispatchToProps = dispatch => ({
    onSetAuthUser: authUser => dispatch({ type: AUTH_USER_SET, authUser }),
    onSetUser: user => dispatch({ type: USER_SET, user })
  });

  return compose(
    withFirebase,
    connect(
      null,
      mapDispatchToProps
    )
  )(WithAuthentication);
};

export default withAuthentication;
