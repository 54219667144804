import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";

// Components
import {
  withAuthorization,
  withEmailVerification
} from "../components/Session";

import { MyListings, AddListingFab } from "../components/Listings";
import Box from "@material-ui/core/Box";

class MyListingsPage extends React.Component {
  render() {
    return (
      <Fragment>
        <MyListings />
        <Box p={4} />
        <AddListingFab />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser
});

const condition = authUser => !!authUser;

// export default withAuthorization(condition)(withEmailVerification(AccountPage));
export default compose(
  connect(mapStateToProps),
  withEmailVerification,
  withAuthorization(condition)
)(MyListingsPage);
