import React, { Component, Fragment } from "react";
import { compose } from "recompose";

// Redux
import { connect } from "react-redux";

// Components
import styles from "../../util/styles";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import Rating from "@material-ui/lab/Rating";

// Google Places API
const placesApiKey = process.env.REACT_APP_PLACES_API_KEY;

const additionalStyles = theme => ({
  ...styles(theme),
  paper: {
    margin: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  }
});

class Place extends Component {
  renderPlace = place => {
    const { locationValue, url, rating } = place;
    // If we have place information, use the location field
    const image = `https://maps.googleapis.com/maps/api/staticmap?markers=${locationValue}&size=400x300&key=${placesApiKey}`;

    return (
      <Fragment>
        <Card>
          <CardActionArea href={url} target="_blank" rel="noopener">
            <Typography className={this.props.classes.headings}>
              {this.props.location}
            </Typography>
            <Typography className={this.props.classes.headings}>
              <Rating
                name="read-only"
                value={rating}
                precision={0.5}
                readOnly
              />
            </Typography>

            <CardMedia component="img" src={image} />

            <Typography
              className={this.props.classes.paper}
              variant="body2"
              component="p"
              color="textSecondary"
            >
              {locationValue}
            </Typography>
          </CardActionArea>
        </Card>
      </Fragment>
    );
  };

  render() {
    const { place } = this.props;

    return (
      (place && Object.keys(place).length !== 0 && this.renderPlace(place)) ||
      null
    );
  }
}

const mapStateToProps = state => ({
  location: state.listingState.location,
  place: state.listingState.place
});

export default compose(
  withStyles(additionalStyles),
  connect(mapStateToProps, null)
)(Place);
