import React, { Component, Fragment } from "react";
import { compose } from "recompose";

// Redux
import { connect } from "react-redux";
import {
  LISTING_START_DATE_SET,
  LISTING_END_DATE_SET
} from "../../redux/types";

// Material UI
import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

export class DatePickerDialog extends Component {
  onStartDateChange = date => {
    this.props.onSetStartDate(date);
  };

  onEndDateChange = date => {
    this.props.onSetEndDate(date);
  };

  render() {
    const { startDate, endDate, datesValid } = this.props;

    // const startLabelError = !datesValid && endDate !== null;
    const startDateError = startDate === null && endDate !== null;
    const endDateError =
      (startDate !== null && endDate === null) || startDate > endDate;
    const endDateErrorText =
      startDate !== null && endDate === null
        ? "Please pick an end date"
        : startDate > endDate
        ? "Must come after the start date"
        : "";

    return (
      <Fragment>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk={true}
              label="Start date"
              error={startDateError}
              helperText={startDateError && "Please pick a start date"}
              // emptyLabel={datesValid ? "" : "Pick a start date"}
              minDateMessage="Date should not be before today"
              clearable={true}
              disablePast={true}
              margin="normal"
              id="date-picker-dialog"
              format="MM/dd/yyyy"
              value={startDate}
              onChange={this.onStartDateChange}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk={true}
              label="End date"
              helperText={endDateErrorText}
              error={endDateError}
              // emptyLabel={datesValid ? "" : "Pick an end date"}
              minDate={startDate}
              minDateMessage="Date must come after the start date"
              clearable={true}
              disablePast={true}
              margin="normal"
              id="date-picker-dialog"
              format="MM/dd/yyyy"
              value={endDate}
              onChange={this.onEndDateChange}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  startDate: state.listingState.startDate,
  endDate: state.listingState.endDate,
  datesValid: state.listingState.datesValid
});

const mapDispatchToProps = dispatch => ({
  onSetStartDate: startDate =>
    dispatch({
      type: LISTING_START_DATE_SET,
      startDate
    }),
  onSetEndDate: endDate =>
    dispatch({
      type: LISTING_END_DATE_SET,
      endDate
    })
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DatePickerDialog
);
