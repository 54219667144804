import React, { Component } from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";

// TODO: this file shares almost exactly the same functionality as NewListing.js

// Redux
import { connect } from "react-redux";
import {
  LISTING_TITLE_SET,
  LISTING_TYPE_SET,
  LISTING_DESCRIPTION_SET,
  LISTING_PRICE_SET,
  LISTING_RESET
} from "../../redux/types";

// Components
import { withFirebase } from "../Firebase";
import ImageUploader from "../Uploaders/ImageUploader";
import DatePickerDialog from "../DatePicker/DatePickerDialog";
import AdornedButton from "../Buttons";
import listingTypes from "./listingTypes";
import Autocomplete from "../Autocomplete";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import styles from "../../util/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";

import uuid from "../../util/uuid";

export class EditListing extends Component {
  state = {
    error: null,
    loading: false
  };

  onSubmit = event => {
    event.preventDefault();
    this.setState({ loading: true });

    const { uid } = this.props.match.params;
    const {
      startDate,
      endDate,
      imagePreviewUrls,
      title,
      listingType,
      location,
      place,
      description,
      price,
      photos
    } = this.props;

    const fileNames = [];

    Promise.all(
      imagePreviewUrls.map((url, index) => {
        const extension = url.split(";")[0].split("/")[1];
        const fileName = `${uuid()}.${extension}`;

        const base64Image = url.split(";base64,")[1];

        const storageRef = this.props.firebase.storage.ref();
        const imageRef = storageRef.child(`images/${fileName}`);

        return imageRef.putString(base64Image, "base64", {
          contentType: "image/jpeg",
          "Cache-Control": "public, max-age=31536000"
        });
      })
    )
      .then(snapshots =>
        Promise.all(
          snapshots.map(snapshot =>
            snapshot.ref
              .getDownloadURL()
              .then(downloadURL => fileNames.push(downloadURL))
          )
        )
      )
      .then(() =>
        this.props.firebase.listing(uid).set(
          {
            title,
            listingType,
            price,
            location,
            place,
            description,
            startDate,
            endDate,
            photos: photos.concat(fileNames),
            updatedAt: this.props.firebase.serverTimestamp()
          },
          { merge: true }
        )
      )
      .then(() => this.props.history.push(`/listings/${uid}`))
      .catch(error => {
        this.setState({ error });
      });
  };

  onTitleChange = event => {
    this.props.onSetTitle(event.target.value);
  };

  onTypeChange = event => {
    this.props.onSetType(event.target.value);
  };

  onDescriptionChange = event => {
    this.props.onSetDescription(event.target.value);
  };

  onPriceChange = event => {
    this.props.onSetPrice(event.target.value);
  };

  render() {
    const { error, loading } = this.state;
    const {
      classes,
      datesValid,
      title,
      listingType,
      location,
      description,
      price
    } = this.props;

    const isInvalid = title === "" || location === "" || !datesValid;

    return (
      <Container component="main" maxWidth="xs">
        <form classes={classes.form} onSubmit={this.onSubmit}>
          <Grid container justify="center" spacing={4}>
            <Grid item xs={12}>
              <Typography
                className={classes.paper}
                component="h3"
                variant="h5"
                // color="textSecondary"
              >
                Edit Your Listing Details
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <ImageUploader disabled={loading} />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete />
            </Grid>

            <Grid item xs={12}>
              <TextField
                disabled={loading}
                name="title"
                value={title}
                variant="standard"
                required
                fullWidth
                id="title"
                label="Listing Title (required)"
                placeholder="e.g. Campsite at Nevada Beach"
                // helperText="Name of your campsite"
                // autoFocus
                onChange={this.onTitleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={loading}
                select
                name="listingType"
                value={listingType}
                variant="standard"
                // required
                fullWidth
                id="listingType"
                label="Type"
                onChange={this.onTypeChange}
                // helperText="Type of listing"
              >
                {listingTypes.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={loading}
                type="number"
                name="price"
                value={price}
                variant="standard"
                // required
                // fullWidth
                id="price"
                label="Price"
                placeholder="per night"
                onChange={this.onPriceChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={loading}
                multiline
                rows="2"
                rowsMax="4"
                name="description"
                value={description}
                variant="standard"
                // required
                fullWidth
                id="description"
                label="Add a brief description"
                placeholder="Amenities, type of site, etc."
                onChange={this.onDescriptionChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                // className={classes.paper}
                variant="body1"
                component="p"
                color="textSecondary"
              >
                When will this be available?
              </Typography>
              <DatePickerDialog disabled={loading} />
            </Grid>
          </Grid>
          <Grid container justify="center" spacing={4}>
            <Grid item xs={12}>
              <AdornedButton
                type="submit"
                fullWidth
                loading={loading}
                disabled={isInvalid}
                variant="contained"
                className={classes.submit}
              >
                Update Listing
              </AdornedButton>
            </Grid>
            <Grid item xs={12}>
              <Typography component="h2" variant="h5">
                {error && <p>{error.message}</p>}
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  startDate: state.listingState.startDate,
  endDate: state.listingState.endDate,
  datesValid: state.listingState.datesValid,
  imagePreviewUrls: state.listingState.imagePreviewUrls,
  title: state.listingState.title,
  listingType: state.listingState.listingType,
  location: state.listingState.location,
  place: state.listingState.place,
  description: state.listingState.description,
  price: state.listingState.price,
  photos: state.listingState.photos
});

const mapDispatchToProps = dispatch => ({
  onSetTitle: title =>
    dispatch({
      type: LISTING_TITLE_SET,
      title
    }),
  onSetType: listingType =>
    dispatch({
      type: LISTING_TYPE_SET,
      listingType
    }),
  onSetDescription: description =>
    dispatch({
      type: LISTING_DESCRIPTION_SET,
      description
    }),
  onSetPrice: price =>
    dispatch({
      type: LISTING_PRICE_SET,
      price
    }),
  onListingReset: () => dispatch({ type: LISTING_RESET })
});

export default compose(
  withStyles(styles),
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(EditListing);
